export const sidehustleData = [
  {
  id: "40",
  category: "Side Hustle Categories",
  subcategory: "Affiliates",
  name: "$0 -$10 in two months",
  video: "https://www.youtube.com/watch?v=dW4wI-PnV40",
  creator: "Chad Bartlett",
  creatorUrl: "https://www.youtube.com/channel/UCuVoO3TArgovwAeWhY1TVSA",
  type: "Side Hustle",
  link: "40",
  },
  {
  id: "38",
  category: "Work Help",
  subcategory: "Productivity Tools",
  name: "10 Awesome AI tools",
  video: "https://www.youtube.com/watch?v=891pzwMZDC8",
  creator: "MysterioTV",
  creatorUrl: "https://www.youtube.com/channel/UChis_1aROPC4yYSkFzBIYTA",
  type: "Info Tube",
  link: "38",
  },
  {
  id: "44",
  category: "Side Hustle Categories",
  subcategory: "Affiliates",
  name: "Affiliate Ads",
  video: "https://www.tiktok.com/@suitesidehustle/video/7012066819016019205?is_from_webapp=1&sender_device=pc&web_id6998243732794902022",
  creator: "suitesidehustle",
  creatorUrl: "https://www.tiktok.com/@suitesidehustle?",
  type: "Info Tube",
  link: "44",
  },
  {
  id: "35",
  category: "Side Hustle Categories",
  subcategory: "Affiliates",
  name: "Affiliate Marketing",
  video: "https://www.tiktok.com/@chadshustle/video/7007827579730169093",
  creator: "chads hustle",
  creatorUrl: "https://www.tiktok.com/@chadshustle?",
  type: "Side Hustle",
  link: "35",
  },
  {
  id: "12",
  category: "Side Hustle Categories",
  subcategory: "Affiliates",
  name: "Affiliate Programs",
  video: "https://www.tiktok.com/@adasonlinebiz/video/6997829400687381765",
  creator: "adas online biz",
  creatorUrl: "https://www.tiktok.com/@adasonlinebiz?",
  type: "Info Tok",
  link: "12",
  },
  {
  id: "14",
  category: "Work Help",
  subcategory: "Productivity Tools",
  name: "Affiliate Traffic",
  video: "https://www.tiktok.com/@adasonlinebiz/video/6996625206596914437",
  creator: "adas online biz",
  creatorUrl: "https://www.tiktok.com/@adasonlinebiz?",
  type: "Info Tok",
  link: "14",
  },
  {
  id: "46",
  category: "",
  subcategory: "",
  name: "Amazon Affiliate",
  video: "https://www.tiktok.com/@suitesidehustle/video/7012802838346599685?is_from_webapp=1&sender_device=pc&web_id6998243732794902022",
  creator: "suitesidehustle",
  creatorUrl: "https://www.tiktok.com/@suitesidehustle?",
  type: "Side Hustle",
  link: "46",
  },
  {
  id: "33",
  category: "Visual Tools",
  subcategory: "Photo Editing and Tools",
  name: "Automate Photo Editing",
  video: "https://www.tiktok.com/@smartbusinessredefined/video/7009250373936631046",
  creator: "smart business redefined",
  creatorUrl: "https://www.tiktok.com/@smartbusinessredefined?",
  type: "Info Tok",
  link: "33",
  },
  {
  id: "3",
  category: "Work Help",
  subcategory: "Productivity Tools",
  name: "Automation with Axiom",
  video: "https://www.tiktok.com/@thesavvymillennial/video/6981139313639574789",
  creator: "the savvy millennial",
  creatorUrl: "https://www.tiktok.com/@thesavvymillennial?",
  type: "Info Tok",
  link: "3",
  },
  {
  id: "32",
  category: "Side Hustle Categories",
  subcategory: "Book Writing",
  name: "Book Writing Process",
  video: "https://www.tiktok.com/@smartbusinessredefined/video/6979709497338121478",
  creator: "smart business redefined",
  creatorUrl: "https://www.tiktok.com/@smartbusinessredefined?",
  type: "Side Hustle",
  link: "32",
  },
  {
  id: "42",
  category: "Income Generator Platform",
  subcategory: "E-Commerce",
  name: "Buy Low - Sell High",
  video: "https://www.tiktok.com/@natehurst/video/6994177685484260614?is_from_webapp=1&sender_device=pc&web_id6998243732794902022",
  creator: "natehurst",
  creatorUrl: "https://www.tiktok.com/@natehurst?",
  type: "Side Hustle",
  link: "42",
  },
  {
  id: "26",
  category: "Work Help",
  subcategory: "Productivity Tools",
  name: "Chip Bot Pylon",
  video: "https://www.tiktok.com/@socialtypro/video/7008158916756425989",
  creator: "socialty pro",
  creatorUrl: "https://www.tiktok.com/@socialtypro?",
  type: "Info Tok",
  link: "26",
  },
  {
  id: "45",
  category: "Side Hustle Categories",
  subcategory: "Affiliates",
  name: "ClickBank + Quora Side Hustle",
  video: "https://www.tiktok.com/@suitesidehustle/video/7013885213835021574?is_from_webapp=1&sender_device=pc&web_id6998243732794902022",
  creator: "suitesidehustle",
  creatorUrl: "https://www.tiktok.com/@suitesidehustle?",
  type: "Side Hustle",
  link: "45",
  },
  {
  id: "29",
  category: "Work Help",
  subcategory: "Productivity Tools",
  name: "Content Generation & More",
  video: "https://www.tiktok.com/@simplydigital/video/6944347162440404230",
  creator: "simply digital",
  creatorUrl: "https://www.tiktok.com/@simplydigital?",
  type: "Info Tok",
  link: "29",
  },
  {
  id: "11",
  category: "Side Hustle Categories",
  subcategory: "Book Writing",
  name: "Create a Book in 3 Steps",
  video: "https://www.tiktok.com/@adasonlinebiz/video/6998127934364077317",
  creator: "adas online biz",
  creatorUrl: "https://www.tiktok.com/@adasonlinebiz?",
  type: "Side Hustle",
  link: "11",
  },
  {
  id: "8",
  category: "Side Hustle Categories",
  subcategory: "Educational Selling",
  name: "Educational Selling",
  video: "https://www.tiktok.com/@thesavvymillennial/video/6998182937900453126",
  creator: "the savvy millennial",
  creatorUrl: "https://www.tiktok.com/@thesavvymillennial?",
  type: "Side Hustle",
  link: "8",
  },
  {
  id: "10",
  category: "Side Hustle Categories",
  subcategory: "Software Testing",
  name: "Freelance Software Testing",
  video: "https://www.tiktok.com/@adasonlinebiz/video/6993626043903003910",
  creator: "adas online biz",
  creatorUrl: "https://www.tiktok.com/@adasonlinebiz?",
  type: "Side Hustle",
  link: "10",
  },
  {
  id: "9",
  category: "Side Hustle Categories",
  subcategory: "Voice Work",
  name: "Freelance Voice Jobs",
  video: "https://www.tiktok.com/@sam_jarman/video/7001243047686196485",
  creator: "sam jarman",
  creatorUrl: "https://www.tiktok.com/@sam_jarman?",
  type: "Side Hustle",
  link: "9",
  },
  {
  id: "15",
  category: "Side Hustle Categories",
  subcategory: "Freelance and Remote Jobs",
  name: "Get Google Certified",
  video: "https://www.tiktok.com/@adasonlinebiz/video/6996218718875290885",
  creator: "adas online biz",
  creatorUrl: "https://www.tiktok.com/@adasonlinebiz?",
  type: "Side Hustle",
  link: "15",
  },
  {
  id: "22",
  category: "Visual Tools",
  subcategory: "Photo Editing and Tools",
  name: "Instagram Canva Ideas Pt. 1",
  video: "https://www.tiktok.com/@samsaini.digitalpreneur/video/7001883040884477189",
  creator: "sam saini",
  creatorUrl: "https://www.tiktok.com/@samsaini?",
  type: "Info Tok",
  link: "22",
  },
  {
  id: "23",
  category: "Visual Tools",
  subcategory: "Photo Editing and Tools",
  name: "Instagram Canva Ideas Pt. 2",
  video: "https://www.tiktok.com/@samsaini.digitalpreneur/video/7001876610584481029",
  creator: "sam saini",
  creatorUrl: "https://www.tiktok.com/@samsaini?",
  type: "Info Tok",
  link: "23",
  },
  {
  id: "39",
  category: "Side Hustle Categories",
  subcategory: "Affiliates",
  name: "Lifetime Affiliates",
  video: "https://www.youtube.com/watch?v=ZpCwMybGc1k",
  creator: "Chad Bartlett",
  creatorUrl: "https://www.youtube.com/channel/UCuVoO3TArgovwAeWhY1TVSA",
  type: "Side Hustle",
  link: "39",
  },
  {
  id: "20",
  category: "Visual Tools",
  subcategory: "Video Making & Editing",
  name: "Make Intro Video",
  video: "https://www.tiktok.com/@samsaini.digitalpreneur/video/7004525057707953413",
  creator: "sam saini",
  creatorUrl: "https://www.tiktok.com/@samsaini?",
  type: "Info Tok",
  link: "20",
  },
  {
  id: "47",
  category: "AI",
  subcategory: "Cool AI Stuff",
  name: "Make Money Using Chat GPT and Medium",
  video: "https://www.youtube.com/watch?v=Mr0zhG2VIiw&list=PL8ab2jQtQQseSB6bsj9Xa6yaCGkdntuXC&index=7",
  creator: "Educate Stream",
  creatorUrl: "https://www.youtube.com/@educatestream",
  type: "Info Tube",
  link: "47",
  },
  {
  id: "48",
  category: "AI",
  subcategory: "Cool AI Stuff",
  name: "Make Money Using Chat GPT and YouTube",
  video: "https://www.youtube.com/watch?v=gpsQ3C7wwTM",
  creator: "Thomas Garetzy",
  creatorUrl: "https://www.youtube.com/@ThomasGaretz",
  type: "Info Tube",
  link: "48",
  },
  {
  id: "30",
  category: "Side Hustle Categories",
  subcategory: "Music",
  name: "Musicians InfoTok",
  video: "https://www.tiktok.com/@lucywarrmusic/video/6980803302367825157",
  creator: "lucy warr music",
  creatorUrl: "https://www.tiktok.com/@lucywarrmusic?",
  type: "Info Tok",
  link: "30",
  },
  {
  id: "37",
  category: "Side Hustle Categories",
  subcategory: "Tutor",
  name: "Passive Income Ideas",
  video: "https://www.youtube.com/watch?v=M5y69v1RbU0",
  creator: "Ali Abdaal",
  creatorUrl: "https://www.youtube.com/channel/UCoOae5nYA7VqaXzerajD0lg",
  type: "Side Hustle",
  link: "37",
  },
  {
  id: "7",
  category: "Visual Tools",
  subcategory: "Photo Editing and Tools",
  name: "Photo Background Removal",
  video: "https://www.tiktok.com/@cottentechcoach/video/6994746473204288774",
  creator: "cotten tech coach",
  creatorUrl: "https://www.tiktok.com/@cottentechcoach?",
  type: "Info Tok",
  link: "7",
  },
  {
  id: "19",
  category: "Work Help",
  subcategory: "Productivity Tools",
  name: "Photo Editing",
  video: "https://www.tiktok.com/@samsaini.digitalpreneur/video/7004529744779775238",
  creator: "sam saini",
  creatorUrl: "https://www.tiktok.com/@samsaini?",
  type: "Info Tok",
  link: "19",
  },
  {
  id: "24",
  category: "Side Hustle Categories",
  subcategory: "Side Hustle Lists",
  name: "Pinterest Side Hustle",
  video: "https://www.tiktok.com/@makemoneyonlinewithsara/video/6979574599210110213",
  creator: "make money online with sara",
  creatorUrl: "https://www.tiktok.com/@makemoneyonlinewithsara?",
  type: "Side Hustle",
  link: "24",
  },
  {
  id: "25",
  category: "Work Help",
  subcategory: "Productivity Tools",
  name: "Press Release",
  video: "https://www.tiktok.com/@socialtypro/video/7007170045319056646",
  creator: "socialty pro",
  creatorUrl: "https://www.tiktok.com/@socialtypro?",
  type: "Info Tok",
  link: "25",
  },
  {
  id: "18",
  category: "Work Help",
  subcategory: "Productivity Tools",
  name: "Profile Pic Maker",
  video: "https://www.tiktok.com/@samsaini.digitalpreneur/video/7004912129581042950",
  creator: "sam saini",
  creatorUrl: "https://www.tiktok.com/@samsaini?",
  type: "Info Tok",
  link: "18",
  },
  {
  id: "16",
  category: "Side Hustle Categories",
  subcategory: "Freelance and Remote Jobs",
  name: "Remote Work",
  video: "https://www.tiktok.com/@adasonlinebiz/video/6995209935931870469",
  creator: "adas online biz",
  creatorUrl: "https://www.tiktok.com/@adasonlinebiz?",
  type: "Side Hustle",
  link: "16",
  },
  {
  id: "28",
  category: "Work Help",
  subcategory: "Productivity Tools",
  name: "Render Forest Side Hustle",
  video: "https://www.tiktok.com/@simplydigital/video/7000722462367304965",
  creator: "simply digital",
  creatorUrl: "https://www.tiktok.com/@simplydigital?",
  type: "Side Hustle",
  link: "28",
  },
  {
  id: "1",
  category: "Work Help",
  subcategory: "Productivity Tools",
  name: "SEMRush",
  video: "https://www.tiktok.com/@simplydigital/video/6997057239521578245",
  creator: "simply digital",
  creatorUrl: "https://www.tiktok.com/@simplydigital?",
  type: "Info Tok",
  link: "1",
  },
  {
  id: "2",
  category: "Side Hustle Categories",
  subcategory: "Side Hustle Lists",
  name: "SideHustleStack",
  video: "https://www.tiktok.com/@sidehustlestack/video/6915264269483281669",
  creator: "side hustle stack",
  creatorUrl: "https://www.tiktok.com/@sidehustlestack?",
  type: "Info Tok",
  link: "2",
  },
  {
  id: "41",
  category: "Side Hustle Categories",
  subcategory: "Affiliates",
  name: "Simple Side Hustle",
  video: "https://www.tiktok.com/@spencerhacks/video/6925479538600332550?is_from_webapp=1&sender_device=pc&web_id6998243732794902022",
  creator: "spencerhacks",
  creatorUrl: "https://www.tiktok.com/@spencerhacks?",
  type: "Side Hustle",
  link: "41",
  },
  {
  id: "36",
  category: "Side Hustle Categories",
  subcategory: "Affiliates",
  name: "Software Affiliate Marketing",
  video: "https://www.youtube.com/watch?v=saXvz4D_Hn4",
  creator: "Chad Bartlett",
  creatorUrl: "https://www.youtube.com/channel/UCuVoO3TArgovwAeWhY1TVSA",
  type: "Side Hustle",
  link: "36",
  },
  {
  id: "5",
  category: "Work Help",
  subcategory: "Productivity Tools",
  name: "Tech Tools Sheet",
  video: "https://www.tiktok.com/@cottentechcoach/video/6975207076326804741",
  creator: "cotten tech coach",
  creatorUrl: "https://www.tiktok.com/@cottentechcoach?",
  type: "Info Tok",
  link: "5",
  },
  {
  id: "43",
  category: "Income Generator Platform",
  subcategory: "E-Commerce",
  name: "T-Shirt On Demand Hustle",
  video: "https://www.youtube.com/watch?v=rI_gXs7I4oM",
  creator: "Kate Theo",
  creatorUrl: "https://www.youtube.com/channel/UC-kXUyNzCw-0QvBrTA1Lb1g",
  type: "Side Hustle",
  link: "43",
  },
  {
  id: "13",
  category: "Side Hustle Categories",
  subcategory: "Tutor",
  name: "Tutor Programs",
  video: "https://www.tiktok.com/@adasonlinebiz/video/6996955807669587205",
  creator: "adas online biz",
  creatorUrl: "https://www.tiktok.com/@adasonlinebiz?",
  type: "Info Tok",
  link: "13",
  },
  {
  id: "34",
  category: "Side Hustle Categories",
  subcategory: "Freelance and Remote Jobs",
  name: "User Testing",
  video: "https://www.tiktok.com/@chadshustle/video/7010425519800028422",
  creator: "chads hustle",
  creatorUrl: "https://www.tiktok.com/@chadshustle?",
  type: "Info Tok",
  link: "34",
  },
  {
  id: "6",
  category: "Visual Tools",
  subcategory: "Video Making & Editing",
  name: "Video Background Removal",
  video: "https://www.tiktok.com/@cottentechcoach/video/6994785267852872965",
  creator: "cotten tech coach",
  creatorUrl: "https://www.tiktok.com/@cottentechcoach?",
  type: "Info Tok",
  link: "6",
  },
  {
  id: "21",
  category: "Visual Tools",
  subcategory: "Video Making & Editing",
  name: "Video Editing",
  video: "https://www.tiktok.com/@samsaini.digitalpreneur/video/7003138707565923590",
  creator: "sam saini",
  creatorUrl: "https://www.tiktok.com/@samsaini?",
  type: "Info Tok",
  link: "21",
  },
  {
  id: "31",
  category: "Visual Tools",
  subcategory: "Video Making & Editing",
  name: "Video Editing InfoTok",
  video: "https://www.tiktok.com/@lucywarrmusic/video/7003784880756690181",
  creator: "lucy warr music",
  creatorUrl: "https://www.tiktok.com/@lucywarrmusic?",
  type: "Info Tok",
  link: "31",
  },
  ];
import React from "react";

const tabs = [
  { id: 1, name: "Website Resources" },
  { id: 2, name: "Side Hustles" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Tabs = ({ activeTab, setActiveTab }) => {
  return (
    <div>

      <div className="visible sm:block">
        <nav
          className="relative z-0 rounded-t-lg shadow flex divide-x divide-gray-200"
          aria-label="Tabs"
        >
          {tabs.map((tab, tabIdx) => (
            <button
              key={tab.name}
              className={classNames(
                activeTab === tab.id
                  ? "text-gray-900"
                  : "text-gray-500 hover:text-gray-700",
                tabIdx === 0 ? "rounded-tl-lg" : "",
                tabIdx === tabs.length - 1 ? "rounded-tr-lg" : "",
                "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
              )}
              aria-current={tab.id === activeTab ? "page" : undefined}
              onClick={() => setActiveTab(tab.id)}
            >
              <span>{tab.name}</span>
              <span
                aria-hidden="true"
                className={classNames(
                  tab.id === activeTab ? "bg-indigo-500" : "bg-transparent",
                  "absolute inset-x-0 bottom-0 h-0.5"
                )}
              />
            </button>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default Tabs;

export const websiteData = [
{
id: "1",
category: "Work Help",
subcategory: "Productivity Tools",
name: "1001 Free Fonts",
description: "1001freefonts.com has over 60000 free fonts for Windows and Mac. Browse fonts by categories such as calligraphy, handwriting, script, serif and more.",
url: "https://www.1001freefonts.com/",
cost: "Free",
},
{
id: "2",
category: "Income Generator Platform",
subcategory: "Services",
name: "99 Designs",
description: "99designs is a graphic design service that makes it easy to work with professional, creative experts from around the world and build your brand through custom, memorable design.",
url: "https://99designs.ca/",
cost: "Percentage",
},
{
id: "3",
category: "Side Hustle Categories",
subcategory: "Voice Work",
name: "ACX Voice Work",
description: "ACX is a marketplace where authors, literary agents, publishers, and other Rights Holders can connect with narrators, engineers, recording studios.",
url: "https://www.acx.com/",
cost: "Free",
},
{
id: "4",
category: "Work Help",
subcategory: "Presentation Help",
name: "Adobe Spark",
description: "Adobe Spark lets you turn your text and photos into a professional-looking, attention-getting graphic. Simply pick a design template, add your photo and text, and quickly resize your creation to fit your favorite social media site or blog.",
url: "https://www.adobe.com/express/",
cost: "Subscription",
},
{
id: "5",
category: "Side Hustle Categories",
subcategory: "Marketing Tools",
name: "AHREFS",
description: "Ahrefs is mainly used to analyze a website's link profile, keyword rankings, and SEO health. You can also use Ahrefs to conduct keyword research for Google, YouTube, and Amazon and more.",
url: "https://ahrefs.com/",
cost: "Paid",
},
{
id: "6",
category: "AI",
subcategory: "Photo Enhancment",
name: "AI Image Enlarger",
description: "AI Image Enlarger is a free online image enlarger that could upscale and enhance small images automatically. Make jpg/png pictures big without losing quality.",
url: "https://imglarger.com/",
cost: "Subcription with Free Tier",
},
{
id: "7",
category: "Work Help",
subcategory: "Productivity Tools",
name: "AISEESOFT Tools",
description: "Aiseesoft offers software solutions to convert, edit and record video/audio files, recover deleted files from iOS/Android/Windows/Mac, etc.",
url: "https://www.aiseesoft.com/",
cost: "Paid",
},
{
id: "8",
category: "Work Help",
subcategory: "Productivity Tools",
name: "AlternativeTo",
description: "AlternativeTo is a website which lists alternatives to web-based software, desktop computer software, and mobile apps.",
url: "https://alternativeto.net/",
cost: "Paid",
},
{
id: "9",
category: "Side Hustle Categories",
subcategory: "Marketing Tools",
name: "Amazing",
description: "Amazing is an Amazon course that has dozens of reviews online giving it excellent ratings. It is geared towards youngsters who want financial independence as an Amazon Seller.",
url: "https://www.amazing.com/",
cost: "Paid",
},
{
id: "10",
category: "Side Hustle Categories",
subcategory: "Affiliates",
name: "Amazon Affiliate",
description: "The Amazon affiliate program helps content creators, publishers and bloggers monetize their traffic and earn supplemental income. Simply put, Amazon will pay you a referral fee every time a customer makes a purchase using your affiliate link.",
url: "https://affiliate-program.amazon.com/",
cost: "Free",
},
{
id: "11",
category: "Side Hustle Categories",
subcategory: "Book Writing",
name: "Amazon Direct Publishing",
description: "Amazon Direct Publishing is for Self-publish eBooks and paperbacks for free with Kindle Direct Publishing,",
url: "https://kdp.amazon.com/en_US/",
cost: "Paid",
},
{
id: "12",
category: "Work Help",
subcategory: "Productivity Tools",
name: "Amazon Ignite",
description: "Amazon Ignite connects educational content creators with Amazon customers. Sell your original teaching resources—like printables, lesson plans, and classroom games—as digital downloads. It’s free to join.",
url: "https://ignite.amazon.com/#/",
cost: "Free",
},
{
id: "13",
category: "Side Hustle Categories",
subcategory: "Marketing Tools",
name: "Amazon Seller App",
description: "Analyze your sales, fulfill orders, find products to sell, manage offers and inventory, and quickly respond to customer questions. Capture and edit professional quality product photos and create listings right from your mobile device!",
url: "https://apps.apple.com/us/app/amazon-seller/id794141485",
cost: "Free",
},
{
id: "14",
category: "Work Help",
subcategory: "Collaboration",
name: "Answer Garden",
description: "AnswerGarden is a new minimalistic feedback tool. Use it for real time audience participation, online brainstorming and classroom feedback.",
url: "https://answergarden.ch/",
cost: "Paid",
},
{
id: "15",
category: "Income Generator Platform",
subcategory: "E-Commerce",
name: "App Sumo",
description: "AppSumo is a daily deals website for digitally distributed goods and online services.",
url: "https://appsumo.com",
cost: "Percentage",
},
{
id: "16",
category: "Work Help",
subcategory: "Productivity Tools",
name: "Audacity",
description: "Audacity is a free and open-source digital audio editor and recording application software, available for Windows, macOS, Linux, and other Unix-like operating systems.",
url: "https://www.audacityteam.org/",
cost: "Free",
},
{
id: "17",
category: "Side Hustle Categories",
subcategory: "Book Writing",
name: "Authors AI",
description: "Authors.ia is an artifical intelligence tool that can detect plot arcs, pacing, character development and more!",
url: "https://authors.ai/",
cost: "Paid",
},
{
id: "18",
category: "Work Help",
subcategory: "Productivity Tools",
name: "Avo Docs",
description: "Avodocs is a document generation software that helps startups draft legal documents including agreement letters and privacy policies.",
url: "https://www.avodocs.com/",
cost: "Free",
},
{
id: "19",
category: "Work Help",
subcategory: "Productivity Tools",
name: "Axiom",
description: "Axiom is a Cloud-based performance management tool that streamlines processes across budgeting, forecasting, planning, reporting, and more.",
url: "https://axiom.ai/",
cost: "Subscription",
},
{
id: "20",
category: "AI",
subcategory: "Photo Enhancment",
name: "Background Eraser",
description: "Removes Backgrounds from photos",
url: "https://magicstudio.com/backgrounderaser",
cost: "Subcription with Free Tier",
},
{
id: "21",
category: "Income Generator Platform",
subcategory: "E-Commerce",
name: "Beacon",
description: "Beacon is a mobile website builder for creators on Instagram and TikTok, with e-commerce and monetization built in. Set up a beautiful page in 5 minutes.",
url: "https://account.beacons.ai/",
cost: "Percentage",
},
{
id: "22",
category: "Work Help",
subcategory: "Presentation Help",
name: "Beautiful AI",
description: "Create professional looking presentations in minutes.",
url: "https://www.beautiful.ai/",
cost: "Free",
},
{
id: "23",
category: "Side Hustle Categories",
subcategory: "Music",
name: "Bensound",
description: "Download Royalty Free Music for free, or paid music, and use it in your project.",
url: "https://www.bensound.com/",
cost: "Paid",
},
{
id: "24",
category: "Visual Tools",
subcategory: "Photo Editing and Tools",
name: "Big JPG",
description: "Image Super-Resolution using Deep Convolutional Neural Networks without quality loss.",
url: "https://deep-image.ai/",
cost: "Paid",
},
{
id: "25",
category: "AI",
subcategory: "Text to Speech",
name: "BigSpeak.ai",
description: "Generate text to speach quickly and easily.",
url: "https://bigspeak.ai/generate/speech/english/",
cost: "Subcription with Free Tier",
},
{
id: "26",
category: "Work Help",
subcategory: "Productivity Tools",
name: "Billo",
description: "Billo is an affordable, easy-to-use, and convenient solution to create content.",
url: "https://billo.app/",
cost: "Subscription",
},
{
id: "27",
category: "Visual Tools",
subcategory: "Video Making & Editing",
name: "Biteable",
description: "Video creation made easy. Biteable is sublimely simple to use and contains all the footage, animations, and templates you'll ever need for social media like TikTok",
url: "https://biteable.com/",
cost: "Paid",
},
{
id: "28",
category: "Side Hustle Categories",
subcategory: "Marketing Tools",
name: "Bitly",
description: "Bitly's Connections Platform is more than a free URL shortener, with robust link management software, advanced QR Code features, and a Link-in-bio solution.",
url: "https://bitly.com/",
cost: "Subcription with Free Tier",
},
{
id: "29",
category: "Side Hustle Categories",
subcategory: "Book Writing",
name: "Book Cover Design",
description: "Canva is a free graphic design platform that's great for making invitations, business cards, Instagram posts, and more. A drag and drop interface makes customizing thousands of templates simple and easy. Canva's wide array of features allow you to edit photos without extensive photo editing knowledge or experience.",
url: "https://www.canva.com/join/qwh-jbb-qxt",
cost: "Paid",
},
{
id: "30",
category: "Work Help",
subcategory: "Presentation Help",
name: "Book Creator",
description: "Book Creator is a digital book-making tool with limitless options, suitable for students of all grade levels.",
url: "https://bookcreator.com/",
cost: "Subscription",
},
{
id: "31",
category: "Side Hustle Categories",
subcategory: "Book Writing",
name: "Books By",
description: "Booksby.ai is an online bookstore which sells science fiction novels generated by an artificial intelligence.",
url: "https://booksby.ai/",
cost: "Percentage",
},
{
id: "32",
category: "Visual Tools",
subcategory: "Photo Editing and Tools",
name: "Brand Mark",
description: "Create a unique logo, business card and social media graphics in less than a minute. Build your brand identity with generative AI.",
url: "https://brandmark.io/",
cost: "Paid",
},
{
id: "33",
category: "Side Hustle Categories",
subcategory: "Web Site Creator",
name: "Bubble",
description: "Bubble is a no-code app building platform designed to help you build software and applications without programming.",
url: "https://bubble.io/",
cost: "Subscription",
},
{
id: "34",
category: "Side Hustle Categories",
subcategory: "Marketing Tools",
name: "Builderall",
description: "Builderall is a website builder and digital marketing all-in-one platform. It's great for entrepreneurs and people starting a new online business. It offers over 30 features, ranging from a drag-and-drop website builder to a sales funnel builder.",
url: "https://builderall.com/",
cost: "Paid",
},
{
id: "35",
category: "Work Help",
subcategory: "Presentation Help",
name: "Buncee",
description: "Buncee is a web-based tool for creating interactive multimedia presentations.",
url: "https://app.edu.buncee.com/",
cost: "Paid",
},
{
id: "36",
category: "Work Help",
subcategory: "Productivity Tools",
name: "Calendly",
description: "Calendly is your hub for scheduling meetings professionally and efficiently, eliminating the hassle of back-and-forth emails so you can get back to work.",
url: "https://calendly.com/",
cost: "Paid",
},
{
id: "37",
category: "Side Hustle Categories",
subcategory: "Tutor",
name: "Cambly Tutoring",
description: "On Cambly, you help people around the world build their English language skills in 1-on-1 lessons.",
url: "https://www.cambly.com/en/tutors?lang=en",
cost: "Free",
},
{
id: "38",
category: "Amazon",
subcategory: "Amazon Tools and Utilities",
name: "CamelCamelCamel",
description: "Camelcamelcamel is a website that tracks prices, and price history of products sold on Amazon.",
url: "https://camelcamelcamel.com/",
cost: "Free",
},
{
id: "39",
category: "Visual Tools",
subcategory: "Photo Editing and Tools",
name: "Canva",
description: "Canva is a free graphic design platform that's great for making invitations, business cards, Instagram posts, and more. A drag and drop interface makes customizing thousands of templates simple and easy. Canva's wide array of features allow you to edit photos without extensive photo editing knowledge or experience.",
url: "https://www.canva.com/join/qwh-jbb-qxt",
cost: "Paid",
},
{
id: "40",
category: "Visual Tools",
subcategory: "Video Making & Editing",
name: "CapCut - Video Editor",
description: "CapCut web is an online, free video editor with tons of features. It's easy to use, no need for video editing experience.",
url: "https://www.capcut.com/tools/online-video-editor",
cost: "Free",
},
{
id: "41",
category: "Work Help",
subcategory: "Productivity Tools",
name: "Capterra",
description: "Capterra helps millions of people find the best business software. With software reviews, ratings, infographics and a comprehensive list of business .",
url: "https://www.capterra.com/",
cost: "Paid",
},
{
id: "42",
category: "Google",
subcategory: "Google Tools and Utilities",
name: "Certify'ems",
description: "Certify'em lets you leverage the power of Google Forms to create online certifications and email custom certificates to exam-takers!",
url: "https://www.certifyem.com/",
cost: "Free",
},
{
id: "43",
category: "AI",
subcategory: "Cool AI Stuff",
name: "ChatGPT",
description: "ChatGPT is a trained model which interacts in a conversational way. The dialogue format makes it possible for ChatGPT to answer your questions.",
url: "https://chat.openai.com/chat",
cost: "Subcription with Free Tier",
},
{
id: "44",
category: "Work Help",
subcategory: "Collaboration",
name: "Chatzy",
description: "Chatzy is free private chat service - create your own chat room and invite people by email.",
url: "https://www.chatzy.com/",
cost: "Paid",
},
{
id: "45",
category: "Work Help",
subcategory: "Productivity Tools",
name: "Cheatography",
description: "Cheatography is a collection of 4714 cheat sheets and quick references in 25 languages for everything from linux to French!",
url: "https://cheatography.com/",
cost: "Paid",
},
{
id: "46",
category: "Work Help",
subcategory: "Productivity Tools",
name: "Chip Bot Pylon",
description: "Use ChipBot Pylon to boost sales with interactive videos and replace live chat with easy self service support. Save money, time, and your sanity.",
url: "https://getchipbot.com/features/pylons",
cost: "Subscription",
},
{
id: "47",
category: "Work Help",
subcategory: "Integration Software",
name: "Chrome Extension Kit",
description: "Chrome Extension Kit includes 17 different battle-tested starter templates to significantly speed up the development of Chrome Extensions.",
url: "https://chromeextensionkit.com/",
cost: "Paid",
},
{
id: "48",
category: "Work Help",
subcategory: "Presentation Help",
name: "Classroom Screen",
description: "Classroomscreen is an online tool that allows you to display the instructions for your lesson in a clear and visual way. Choose from over 13 widgets to support your class activities and help students get to work.",
url: "https://classroomscreen.com/",
cost: "Subscription",
},
{
id: "49",
category: "Visual Tools",
subcategory: "Photo Editing and Tools",
name: "Cleanup Pictures",
description: "Cleanup.pictures is a handy online service to remove objects from images that you upload to it. It is simple to use and results are usually very good. The only downside to using the service is that the output images may have a reduced resolution. Then again, the online application is free to use.",
url: "https://cleanup.pictures",
cost: "Subcription with Free Tier",
},
{
id: "50",
category: "Income Generator Platform",
subcategory: "E-Commerce",
name: "Click a Snap",
description: "ClickASnap is a photo sharing platform to help you share your images and get paid for it.",
url: "https://www.clickasnap.com/",
cost: "Percentage",
},
{
id: "51",
category: "Side Hustle Categories",
subcategory: "Affiliates",
name: "Click Bank",
description: "Clickbank is both a marketplace for affiliates and an e-commerce platform for digital content creators. As a content owner, you can add your product to Clickbank's database and they will manage the sale and checkout process, as well as making it visible to their network of affiliate marketers.",
url: "https://www.clickbank.com/",
cost: "Paid",
},
{
id: "52",
category: "Side Hustle Categories",
subcategory: "Affiliates",
name: "Click Bank",
description: "Clickbank is an e-commerce platform that connects over 6 million digital content creators and affiliate marketers, who then promote them to consumers.",
url: "https://www.clickbank.com/",
cost: "Paid",
},
{
id: "53",
category: "Work Help",
subcategory: "Productivity Tools",
name: "Content Design - Jitter",
description: "Jitter is great to animate your designs easily. Export your creations as videos or GIFs.",
url: "https://jitter.video/",
cost: "Paid",
},
{
id: "54",
category: "Work Help",
subcategory: "Productivity Tools",
name: "Content Drips",
description: "Contentdrips allows you to create bite-sized videos, photos & carousel post for your LinkedIn & all other social media channels. ContentDrips is a automated micro content creation tool built for influencers, digital nomads and entrepreneurs. It helps you create a source for micro content.",
url: "https://contentdrips.com/",
cost: "Subscription",
},
{
id: "55",
category: "Side Hustle Categories",
subcategory: "Freelance and Remote Jobs",
name: "Contra",
description: "Contra is a professional community for the independent digital workforce. Digital professionals use Contra to create stunning visual profiles, earn money commission-free, and build high-quality professional communities and referral networks.",
url: "https://contra.com/",
cost: "Paid",
},
{
id: "56",
category: "Work Help",
subcategory: "Email tools",
name: "Convert Kit",
description: "ConvertKit is a cloud-based lead generation and email marketing automation solution aimed at professional bloggers, podcasters, YouTubers, course creators, and other content-driven business owners",
url: "https://convertkit.com/",
cost: "Subscription",
},
{
id: "57",
category: "Visual Tools",
subcategory: "Color Palette",
name: "Coolors",
description: "Coolors is the essential tool for creating and collecting color palettes. It allows you to generate perfect matching colors in seconds.",
url: "https://coolors.co/",
cost: "Free",
},
{
id: "58",
category: "AI",
subcategory: "Text Summarization & Copy",
name: "Copy.ai",
description: "Copy.ai is an outstanding service company that leverages artificial intelligence to make copywriting easy by essentially writing your copy for you. It helps you generate written content within seconds and makes your draft look as authentic as possible.",
url: "https://www.copy.ai/",
cost: "Subcription with Free Tier",
},
{
id: "59",
category: "Work Help",
subcategory: "Productivity Tools",
name: "Cue Prompter",
description: "CuePrompter is a free teleprompter/autocue service. Your browser works like a teleprompter -no extra software needed.",
url: "https://cueprompter.com/",
cost: "Paid",
},
{
id: "60",
category: "Income Generator Platform",
subcategory: "Services",
name: "Curated",
description: "Curated.com is an online shopping experience with a human touch. They are a collaborative online shopping service intended to assist shoppers making expensive purchases. Curated matches customers with experts for items that have some degree of complexity and are usually expensive.",
url: "https://www.curated.com",
cost: "Percentage",
},
{
id: "61",
category: "Work Help",
subcategory: "Productivity Tools",
name: "Da Font",
description: "DaFont is probably the most popular free fonts website in the world.",
url: "https://www.dafont.com/",
cost: "Free",
},
{
id: "62",
category: "AI",
subcategory: "Cool AI Stuff",
name: "Dall E",
description: "DALL·E 2 is an AI system that can create realistic images and art from a description in natural language.",
url: "https://openai.com/dall-e-2/",
cost: "Free",
},
{
id: "63",
category: "Visual Tools",
subcategory: "Photo Editing and Tools",
name: "Deep Image",
description: "Online image enhancer to increase image size, upscale photo, improve picture quality, and increase image resolution.",
url: "https://deep-image.ai/",
cost: "Paid",
},
{
id: "64",
category: "Visual Tools",
subcategory: "Photo Editing and Tools",
name: "Deep Nostalgio",
description: "Deep Nostalgia is a video reenactment technology to animate the faces in still photos and create high-quality, realistic video footage.",
url: "https://www.myheritage.com/deep-nostalgia",
cost: "Paid",
},
{
id: "65",
category: "Side Hustle Categories",
subcategory: "Book Writing",
name: "Deep Story AI",
description: "DeepStory is an AI powered script and story generator developed by ScriptBook.",
url: "https://deepstory.ai",
cost: "Free",
},
{
id: "66",
category: "Visual Tools",
subcategory: "Photo Editing and Tools",
name: "Designify",
description: "Designify combines visual AI's into one simple tool. Remove image backgrounds, add realistic shadows to objects and enhance colors by using artificial intelligence.",
url: "https://www.designify.com/",
cost: "Paid",
},
{
id: "67",
category: "Work Help",
subcategory: "Course & Certification",
name: "Digital Defynd",
description: "Digital Defynd helps you find the best courses, certifications and tutorials online.",
url: "https://digitaldefynd.com/",
cost: "Paid",
},
{
id: "68",
category: "Side Hustle Categories",
subcategory: "Affiliates",
name: "Disney Affiliates",
description: "Referral affiliate site for Disney",
url: "https://www.shopdisney.com/affiliates.html",
cost: "Free",
},
{
id: "69",
category: "Visual Tools",
subcategory: "Photo Editing and Tools",
name: "DouTone Shapefactory",
description: "Duotone Shapefactory allows you to make beautiful free duotone images for any project, by simply uploading your own image and applying a duotone effect you want.",
url: "https://duotone.shapefactory.co/",
cost: "Free",
},
{
id: "70",
category: "Twitter",
subcategory: "Twitter Tools and Utilities",
name: "Download Twitter Videos",
description: "Twitter video download site",
url: "https://twdown.net/",
cost: "Free",
},
{
id: "71",
category: "Work Help",
subcategory: "Productivity Tools",
name: "Drone Stock",
description: "Dronestock.com is a online resource for free video footage captured by a drone.",
url: "https://dronestock.com/",
cost: "Paid",
},
{
id: "72",
category: "AI",
subcategory: "Text Enhancement",
name: "Duplichecker",
description: "DupliChecker is free and Its user-friendly interface allows you to quickly rewrite text and make them plagiarism-free.",
url: "https://www.duplichecker.com/article-rewriter.php",
cost: "Free",
},
{
id: "73",
category: "Work Help",
subcategory: "Productivity Tools",
name: "Easel.ly",
description: "Easel.ly is a simple web tool that empowers anyone to create and share powerful visuals",
url: "https://www.easel.ly/",
cost: "Subscription",
},
{
id: "74",
category: "Work Help",
subcategory: "Email tools",
name: "Email JS",
description: "EmailJS is a service that allows us to send emails directly from your client-side JavaScript code.",
url: "https://www.emailjs.com/",
cost: "Free",
},
{
id: "75",
category: "Side Hustle Categories",
subcategory: "Software Testing",
name: "Enroll app",
description: "Enroll is a market research company that you can join to test websites, content, or logos using your desktop, laptop, smartphone, or even your tablet.",
url: "https://enrollapp.com/",
cost: "Paid",
},
{
id: "76",
category: "Side Hustle Categories",
subcategory: "Marketing Tools",
name: "eRank",
description: "Erank helps you figure out the best keyword to get your listing ranking, as well as comparing your listing and keywords to other sellers.",
url: "https://erank.com/",
cost: "Free",
},
{
id: "77",
category: "AI",
subcategory: "Text Summarization & Copy",
name: "EssayBot",
description: "EssayBot is your personal AI writing tool. With your essay title, EssayBot suggests most relevant contents.",
url: "https://www.essayailab.com/",
cost: "Free",
},
{
id: "78",
category: "Income Generator Platform",
subcategory: "E-Commerce",
name: "Esty",
description: "Etsy, Inc. is an American e-commerce company focused on handmade or vintage items and craft supplies.",
url: "https://www.etsy.com",
cost: "Percentage",
},
{
id: "79",
category: "Work Help",
subcategory: "Productivity Tools",
name: "Fame Swap",
description: "Fameswap is a marketplace where you can buy established instagram accounts with likes, followers and real audiences.",
url: "https://fameswap.com/",
cost: "Paid",
},
{
id: "80",
category: "Side Hustle Categories",
subcategory: "Marketing Tools",
name: "Fan Fuel",
description: "FanFuel is a sports sponsorship platform that allows brands to easily search for athletes, secure sponsorship deals, and measure their return on investment while also helping athletes understand their marketing potential.",
url: "https://fanfuel.co/",
cost: "Free",
},
{
id: "81",
category: "Side Hustle Categories",
subcategory: "Freelance and Remote Jobs",
name: "Ferpection",
description: "Make money testing sites, mobile apps or concepts! Ferpection helps companies best meet the expectations and behaviors of users on their websites, mobile applications or in stores.",
url: "https://ferpection.com/",
cost: "Free",
},
{
id: "82",
category: "Income Generator Platform",
subcategory: "Services",
name: "Fiverr",
description: "Fiverr is an online marketplace that connects businesses with on-demand freelance talent offering digital services in more than 400 categories, across 8 verticals including graphic design, digital marketing, programming, video and animation.",
url: "https://www.fiverr.com/",
cost: "Percentage",
},
{
id: "83",
category: "Work Help",
subcategory: "Productivity Tools",
name: "Flat Icon",
description: "Flaticon has more than a million icons and stickers in all formats, for all kind of projects: presentations, apps, websites, catalogs, infographics etc.",
url: "https://flaticon.com",
cost: "Paid",
},
{
id: "84",
category: "Visual Tools",
subcategory: "Video Making & Editing",
name: "flex clip",
description: "FlexClip is an easy-to-use video maker that can help both professionals and beginners create beautiful videos in minutes. FlexClip's powerful video editor lets you trim/split to select the best part of your video, and give it a personal touch with customizable text animations, music, etc.",
url: "https://flexclip.com",
cost: "Free",
},
{
id: "85",
category: "Work Help",
subcategory: "Collaboration",
name: "Flinga",
description: "Flinga is a Finnish application that diversifies the interaction between a teacher and students in a classroom. By using Flinga students can easily produce content together directly via browser.",
url: "https://flinga.fi/",
cost: "Paid",
},
{
id: "86",
category: "Work Help",
subcategory: "Presentation Help",
name: "Flip Grid",
description: "Flipgrid is a website and app that allows teachers to facilitate video discussions. Students are organized into groups and then given access to discussion topics.",
url: "https://info.flipgrid.com/",
cost: "Paid",
},
{
id: "87",
category: "Work Help",
subcategory: "Presentation Help",
name: "Flip Snack",
description: "FlipSnack is an online publishing platform for uploading predesigned content to make a 'flippable' virtual book.",
url: "https://www.flipsnack.com/",
cost: "Subscription",
},
{
id: "88",
category: "Work Help",
subcategory: "Email tools",
name: "Form Spree",
description: "Formspree is a form backend, API, and email service for HTML & JavaScript forms.",
url: "https://formspree.io/",
cost: "Free",
},
{
id: "89",
category: "Income Generator Platform",
subcategory: "Services",
name: "Freelancer Club",
description: "Freelancerclub.net is dedicated to helping creative freelance talent achieve their goals. Access jobs, meet freelancers, promote your work and get support.",
url: "https://freelancerclub.net/",
cost: "Subscription",
},
{
id: "90",
category: "Income Generator Platform",
subcategory: "Services",
name: "Go Share",
description: "GoShare connects people who have trucks and muscles with consumers who need help moving. It pays a good wage to both movers and helpers.",
url: "https://goshare.co/",
cost: "",
},
{
id: "91",
category: "Work Help",
subcategory: "Productivity Tools",
name: "Google Ads",
description: "Google Ads is an online advertising platform developed by Google.",
url: "https://ads.google.com/intl/en_ca/home/",
cost: "Paid",
},
{
id: "92",
category: "Work Help",
subcategory: "Collaboration",
name: "Google Forms",
description: "Google forms lets you create a new survey and edit it with others at the same time. Choose from a variety of pre-made themes or create your own.",
url: "https://docs.google.com/",
cost: "Paid",
},
{
id: "93",
category: "Side Hustle Categories",
subcategory: "Web Site Creator",
name: "Google Sites",
description: "Google sites lets you build internal project hubs, team sites, public-facing websites, and more—all without designer, programmer, or IT help.",
url: "https://sites.google.com/new",
cost: "Paid",
},
{
id: "94",
category: "Work Help",
subcategory: "Presentation Help",
name: "Google Slides",
description: "Google slides lets you create a new presentation and edit it with others at the same time — from your computer, phone or tablet.",
url: "https://docs.google.com/",
cost: "Paid",
},
{
id: "95",
category: "Work Help",
subcategory: "Funnels",
name: "Groove Funnels",
description: "Groove.cm is an all-in-one marketing software suite that is also a stand-alone substitute for many well-known software and tools currently used in online businesses.",
url: "https://groove.cm/",
cost: "Paid",
},
{
id: "96",
category: "Income Generator Platform",
subcategory: "Services",
name: "Handy",
description: "Handy is the leading platform for connecting individuals looking for household services with top-quality, pre-screened independent service professionals.",
url: "https://www.handy.com/",
cost: "Paid",
},
{
id: "97",
category: "Work Help",
subcategory: "Productivity Tools",
name: "Hashtag Stack",
description: "HashtagStack is a hashtag generator tool to help you find the best hashtags that will help you grow your account.",
url: "https://hashtagstack.com/hashtag-generator",
cost: "Free",
},
{
id: "98",
category: "Work Help",
subcategory: "Productivity Tools",
name: "Hopps",
description: "Hopps is an instant and on-demand expert collaboration and upskilling platform for enterprise teams.",
url: "https://www.hopps.io/",
cost: "Paid",
},
{
id: "99",
category: "Side Hustle Categories",
subcategory: "Marketing Tools",
name: "Hot Jar",
description: "Hotjar is a product experience insights tool that gives you behavior analytics and feedback data to help you empathize with and understand your customers through tools like Heatmaps, Session Recordings, Surveys, and an Incoming Feedback widget.",
url: "https://www.hotjar.com/",
cost: "Paid",
},
{
id: "100",
category: "Work Help",
subcategory: "Productivity Tools",
name: "Hotpot AI",
description: "Hotpot.ai is an easy way to create device mockups, logos, social media ads, and other graphics.",
url: "https://hotpot.ai/",
cost: "Paid",
},
{
id: "101",
category: "Side Hustle Categories",
subcategory: "Marketing Tools",
name: "H-Supertools",
description: "H-supertools provides the best 100% free SEO and Digital Marketing tools on the internet to boost your online business.",
url: "https://h-supertools.com/",
cost: "Free",
},
{
id: "102",
category: "AI",
subcategory: "Text Enhancement",
name: "Hugging Face",
description: "Hugging Face is a community and data science platform that provides: Tools that enable users to build, train and deploy ML models based on open source (OS) code and technologies.",
url: "https://huggingface.co/",
cost: "Subcription with Free Tier",
},
{
id: "103",
category: "Work Help",
subcategory: "Productivity Tools",
name: "Icons8",
description: "Icon8 lets you download design elements for items such as icons, photos, vector illustrations, and music for your videos.",
url: "https://icons8.com/",
cost: "Paid",
},
{
id: "104",
category: "Visual Tools",
subcategory: "Photo Editing and Tools",
name: "Igram for Instagram",
description: "iGram is an online web tool to help you with downloading Instagram Photos, Videos and IGTV videos.",
url: "https://igram.io/",
cost: "Paid",
},
{
id: "105",
category: "Instagram",
subcategory: "Instagram Tools and Utilities",
name: "Igram for Instagram",
description: "iGram is an online web tool to help you with downloading Instagram Photos, Videos and IGTV videos.",
url: "https://igram.io/",
cost: "Paid",
},
{
id: "106",
category: "Visual Tools",
subcategory: "Photo Editing and Tools",
name: "Imagen-AI",
description: "Imagen-AI is an AI-powered photo editing desktop app for Adobe Lightroom workflows.",
url: "https://www.imagen-ai.com/",
cost: "Paid",
},
{
id: "107",
category: "Side Hustle Categories",
subcategory: "Music",
name: "Indie Bible",
description: "The Indie Bible is a powerful internet-based database that allows you to quickly locate music services that fit your specific needs.",
url: "https://indiebible.com/",
cost: "Paid",
},
{
id: "108",
category: "Side Hustle Categories",
subcategory: "Marketing Tools",
name: "Indie Hackers",
description: "Indie Hackers is a place where the founders of profitable businesses and side projects can share their stories transparently, and where entrepreneurs can come to read and learn from those examples.",
url: "https://www.indiehackers.com/",
cost: "Free",
},
{
id: "109",
category: "Work Help",
subcategory: "Productivity Tools",
name: "Info Gram",
description: "Infogram is an intuitive visualization tool that empowers people and teams to create beautiful content.",
url: "https://infogram.com/",
cost: "Subscription",
},
{
id: "110",
category: "Income Generator Platform",
subcategory: "Services",
name: "InstaCart",
description: "Instacart is a grocery delivery service in the United States and Canada.",
url: "https://www.instacart.com/",
cost: "Paid",
},
{
id: "111",
category: "Visual Tools",
subcategory: "Photo Editing and Tools",
name: "Instagram Posts CANVA.com",
description: "CANVA Instragram post builder to share your passion or build your business with more engaging Insta stories, posts, and ads.",
url: "https://www.canva.com/instagram-posts/templates/",
cost: "Paid",
},
{
id: "112",
category: "Visual Tools",
subcategory: "Video Making & Editing",
name: "Instants App",
description: "Photo editing tool for iPhone, iPad",
url: "https://apps.apple.com/us/app/instants-photo-edition/id947399666",
cost: "Paid",
},
{
id: "113",
category: "Income Generator Platform",
subcategory: "Services",
name: "Instawork",
description: "Instawork.com for catering,  hospitality, warehouse associates and delivery drivers, you can easily browse and apply to hourly shifts that fit your schedule.",
url: "https://www.instawork.com",
cost: "Free",
},
{
id: "114",
category: "Work Help",
subcategory: "Education",
name: "Instructables",
description: "Instructables is a website specializing in user-created and uploaded do-it-yourself projects, which other users can comment on and rate for quality.",
url: "https://www.instructables.com/",
cost: "Paid",
},
{
id: "115",
category: "Visual Tools",
subcategory: "Video Making & Editing",
name: "InVideo",
description: "InVideo.io to create branded videos with 9MN+ of premium stock videos. No video editing skills required. Create engaging videos easily with pro-quality, customizable templates and 9M+ stock media. Automated Voice Overs. 24x7 Support. Types: IG Story Maker, FB Video Maker.  GARETZ30 code to save 30%",
url: "https://invideo.io/",
cost: "Subcription with Free Tier",
},
{
id: "116",
category: "Side Hustle Categories",
subcategory: "Marketing Tools",
name: "Jarvis AI",
description: "Create content 5x faster with artificial intelligence using the Jarvis.ai copywriting tool.",
url: "https://www.jarvis.ai/",
cost: "Paid",
},
{
id: "117",
category: "Visual Tools",
subcategory: "Video Making & Editing",
name: "Jitter",
description: "Jitter is an easy-to-use animation tool on the web.",
url: "https://jitter.video/",
cost: "Subscription",
},
{
id: "118",
category: "Visual Tools",
subcategory: "Video Making & Editing",
name: "Kamua",
description: "Kamua is a new artificial intelligence-powered video-editing platform.",
url: "https://kamua.com/",
cost: "Subscription",
},
{
id: "119",
category: "Visual Tools",
subcategory: "Video Making & Editing",
name: "Kapwing",
description: "Kapwing is an online image, video, and GIF editing platform that empowers people to make and share their stories on the internet.",
url: "https://www.kapwing.com/",
cost: "Paid",
},
{
id: "120",
category: "Side Hustle Categories",
subcategory: "Marketing Tools",
name: "Later",
description: "Later is a social media management tool that allows you to schedule posts across Twitter, Pinterest, Facebook, and Instagram. It also offers a free plan, enabling you to post up to 60 pieces of content per platform per month.",
url: "https://later.com/",
cost: "Paid",
},
{
id: "121",
category: "Side Hustle Categories",
subcategory: "Marketing Tools",
name: "Legendary Marketer",
description: "Legendary Marketer is a comprehensive online training university for aspiring digital entrepreneurs made up of digital courses, eBooks, one-on-one coaching, support groups, and even live networking events where students meet six and seven-figure entrepreneurs.",
url: "https://legendarymarketer.com/",
cost: "Paid",
},
{
id: "122",
category: "Work Help",
subcategory: "Productivity Tools",
name: "Link Tree",
description: "Linktree is the launchpad to your latest video, article, recipe, tour, store, website, social post - everywhere you are online.",
url: "https://linktr.ee/",
cost: "Paid",
},
{
id: "123",
category: "Work Help",
subcategory: "Productivity Tools",
name: "Live Binders",
description: "LiveBinders is a free online bookmarking site that provides a unique method of storing online resources and sharing those resources with others.",
url: "https://www.livebinders.com/welcome/",
cost: "Subscription",
},
{
id: "124",
category: "Work Help",
subcategory: "Productivity Tools",
name: "Looka",
description: "Looka is an AI-powered graphic design platform that lets you design a brand identity from scratch, starting with your logo.",
url: "https://looka.com/logo-maker/",
cost: "Paid",
},
{
id: "125",
category: "Visual Tools",
subcategory: "Video Making & Editing",
name: "Loopsie App",
description: "Loopsie lets you create stunning 3D Photos, D3D Camera Parallax like videos, Smart Long Exposure, Light Trails, Living Photos and much more.",
url: "https://play.google.com/store/apps/details?id=com.loopsie.android&hl=en_CA&gl=US",
cost: "Subscription",
},
{
id: "126",
category: "Work Help",
subcategory: "Productivity Tools",
name: "Lucid Press",
description: "Lucidpress is a web-based desktop publishing software application to create brochures, flyers, newsletters, business cards, posters, magazines and presentations.",
url: "https://app.lucidpress.com/",
cost: "Subscription",
},
{
id: "127",
category: "Income Generator Platform",
subcategory: "Services",
name: "Lyft",
description: "Lyft develops, markets, and operates a mobile app, offering vehicles for hire, motorized scooters, a bicycle-sharing system, and food delivery.",
url: "https://www.lyft.com",
cost: "Percentage",
},
{
id: "128",
category: "AI",
subcategory: "Photo Enhancment",
name: "Magic Eraser",
description: "Erase parts of an image you don't want.",
url: "https://magicstudio.com/magiceraser",
cost: "Subcription with Free Tier",
},
{
id: "129",
category: "Side Hustle Categories",
subcategory: "Music",
name: "Major Contacts",
description: "MajorContacts.com is the most reliable and up-to-date directory and submission platform for the entertainment industry.",
url: "https://majorcontacts.com/",
cost: "",
},
{
id: "130",
category: "Side Hustle Categories",
subcategory: "Marketing Tools",
name: "Marmalead",
description: "Marmalead is an Etsy SEO and market research tool for serious sellers. It is software that enables Etsy sellers to remain competitive in an ever changing ecommerce landscape through better market research and SEO analysis.",
url: "https://marmalead.com/",
cost: "Paid",
},
{
id: "131",
category: "Side Hustle Categories",
subcategory: "Marketing Tools",
name: "Medium",
description: "Medium is an open platform where readers find dynamic thinking, and where expert and undiscovered voices can share their writing on any topic.",
url: "https://medium.com/",
cost: "",
},
{
id: "132",
category: "Work Help",
subcategory: "Collaboration",
name: "Menti Meter",
description: "Mentimeter enables you to: Engage with students using live polls, word clouds, quizzes, multiple-choice questions and more.",
url: "https://www.mentimeter.com/",
cost: "Subscription",
},
{
id: "133",
category: "Income Generator Platform",
subcategory: "Services",
name: "Meowtel",
description: "Meowtel.com connects busy cat parents with trusted and insured cat sitters",
url: "https://meowtel.com",
cost: "Percentage",
},
{
id: "134",
category: "AI",
subcategory: "Photo Enhancment",
name: "MidJourney",
description: "Midjourney is an independent research lab exploring new mediums of thought and expanding the imaginative powers of the human species.",
url: "https://www.midjourney.com/home/?callbackUrl=%2Fapp%2F",
cost: "Free",
},
{
id: "135",
category: "Work Help",
subcategory: "Integration Software",
name: "Mini Extensions",
description: "miniExtensions is a third party tool for Airtable for portals and more.",
url: "https://miniextensions.com/",
cost: "Subscription",
},
{
id: "136",
category: "Visual Tools",
subcategory: "Stock Photos",
name: "Mix Kit",
description: "Mixkit.co is a site that offers free stock videos, free stock music, free sound effects, and free video templates.",
url: "https://mixkit.co/",
cost: "Paid",
},
{
id: "137",
category: "Work Help",
subcategory: "Productivity Tools",
name: "Mock Magic",
description: "Mock Magic is a web app to generate high quality device mockups for your next presentation, website, social media campaign, portfolio and more.",
url: "https://www.mockmagic.com/",
cost: "Free",
},
{
id: "138",
category: "Work Help",
subcategory: "Productivity Tools",
name: "Mockup Bro",
description: "MockupBro is an online mockup generator that works in three simple steps: you choose your favorite template, upload your design to integrate it, and download your final image.",
url: "https://mockupbro.com/",
cost: "Paid",
},
{
id: "139",
category: "Visual Tools",
subcategory: "Video Making & Editing",
name: "Mojo",
description: "mojo is an app to create social stories with a professional look. The app offers hundreds of animated templates to create eye-catching stories.",
url: "https://www.mojo-app.com/",
cost: "",
},
{
id: "140",
category: "Visual Tools",
subcategory: "Video Making & Editing",
name: "Momento",
description: "Memento.com isa platform to record, make, and preserve memories from the most meaningful life celebrations and milestones.",
url: "https://www.memento.com/",
cost: "Subscription",
},
{
id: "141",
category: "Side Hustle Categories",
subcategory: "Marketing Tools",
name: "Morning Fame",
description: "Morningfame guides you through the process of optimizing your videos. This guided process is intuitive to understand and yet applies best practices from experts.  Invite code: tgaretz.com/morningfame",
url: "https://morningfa.me/",
cost: "",
},
{
id: "142",
category: "Side Hustle Categories",
subcategory: "Music",
name: "Music Gateway",
description: "Music Gateway is an all-in-one music service platform that provides various tools and products for musicians and struggling artists.",
url: "https://www.musicgateway.com",
cost: "Paid",
},
{
id: "143",
category: "Side Hustle Categories",
subcategory: "Music",
name: "Muso Soup",
description: "Musosoup is a paid platform where you can submit your tracks to playlists, radio stations, and blogs.",
url: "https://www.musosoup.com/",
cost: "Paid",
},
{
id: "144",
category: "Side Hustle Categories",
subcategory: "Book Writing",
name: "My Identifiers",
description: "My Identifiers is the only official website of the U.S. ISBN Agency. They provide you with the products and services to make your books more discoverable.",
url: "https://www.myidentifiers.com/",
cost: "Paid",
},
{
id: "145",
category: "AI",
subcategory: "Photo Enhancment",
name: "MyHeritage - AI Time Machine",
description: "Create avatars of your images",
url: "https://www.myheritage.com/ai-time-machine",
cost: "",
},
{
id: "146",
category: "AI",
subcategory: "Photo Enhancment",
name: "MyHeritage - Animate Photos",
description: "Animate the faces in your family photos with amazing technology. Experience your family history like never before!",
url: "https://www.myheritage.com/deep-nostalgia?tr_id=m_4x73smnsbz_ywdlwtuya7",
cost: "Subcription with Free Tier",
},
{
id: "147",
category: "AI",
subcategory: "Photo Enhancment",
name: "MyHeritage - Colorize Photos",
description: "Colorize photos and be amazed",
url: "https://www.myheritage.com/incolor?tr_id=m_4x73smnsbz_ywdlwtuya7",
cost: "Subcription with Free Tier",
},
{
id: "148",
category: "AI",
subcategory: "Photo Enhancment",
name: "MyHeritage - Enhance Photos",
description: "Enhance low resolution photos",
url: "https://www.myheritage.com/photo-enhancer?tr_id=m_4x73smnsbz_ywdlwtuya7",
cost: "Subcription with Free Tier",
},
{
id: "149",
category: "Work Help",
subcategory: "Productivity Tools",
name: "Name Lix",
description: "Namelix uses artificial intelligence to create a short, brandable business name.",
url: "https://namelix.com/",
cost: "Paid",
},
{
id: "150",
category: "AI",
subcategory: "Text Enhancement",
name: "NameLix",
description: "Namelix generates short, branded names that are relevant to your business idea. When you save a name, the algorithm learns your preferences and gives you more.",
url: "https://namelix.com/",
cost: "Free",
},
{
id: "151",
category: "Work Help",
subcategory: "Presentation Help",
name: "Near Pod",
description: "Nearpod helps educators make any lesson interactive whether in the classroom or virtual.",
url: "https://nearpod.com/",
cost: "Paid",
},
{
id: "152",
category: "Side Hustle Categories",
subcategory: "Affiliates",
name: "Nike Affiliates",
description: "Referral affiliate site for Nike.",
url: "https://www.nike.com/gb/help/a/affiliate-program-eu",
cost: "Free",
},
{
id: "153",
category: "Work Help",
subcategory: "Productivity Tools",
name: "Notion.so",
description: "Notion is a new tool that blends your everyday work apps into one. It's the all-in-one workspace for you and your team.",
url: "https://www.notion.so/",
cost: "Paid",
},
{
id: "154",
category: "Work Help",
subcategory: "Productivity Tools",
name: "Omni Calculator",
description: "Omni Calculator solves 2031 problems anywhere from finance and business to health. It's so fast and easy you won't want to do the math again!",
url: "https://www.omnicalculator.com/",
cost: "Free",
},
{
id: "155",
category: "Work Help",
subcategory: "Productivity Tools",
name: "Online Chart Tool",
description: "Onlinecharttool.com is a site where you can design and share your own charts online and for free.",
url: "https://www.onlinecharttool.com/",
cost: "Paid",
},
{
id: "156",
category: "Work Help",
subcategory: "Productivity Tools",
name: "Online-Convert",
description: "Online-Convert.com is an online file converter that allows you to convert files from one format into another.",
url: "https://www.online-convert.com/",
cost: "Paid",
},
{
id: "157",
category: "AI",
subcategory: "Cool AI Stuff",
name: "Open AI - Beta",
description: "Some exciting AI projects Open AI is working on",
url: "https://beta.openai.com/examples",
cost: "Free",
},
{
id: "158",
category: "Work Help",
subcategory: "Collaboration",
name: "PadLet",
description: "Padlet is a web app that lets users post notes on a digital wall.",
url: "https://padlet.com/features",
cost: "Subscription",
},
{
id: "159",
category: "Visual Tools",
subcategory: "Video Making & Editing",
name: "Panzoid",
description: "Panzoid is a community and tools for creating beautiful, custom content.",
url: "https://panzoid.com/",
cost: "Paid",
},
{
id: "160",
category: "Income Generator Platform",
subcategory: "Health & Fitness",
name: "Papa",
description: "JoinPapa is a platform that connects college students to senior citizens for companionship and assistance.",
url: "https://www.joinpapa.com/",
cost: "",
},
{
id: "161",
category: "Work Help",
subcategory: "Presentation Help",
name: "Pear Deck",
description: "Pear Deck is an interactive presentation tool used to actively engage students in individual and social learning.",
url: "https://www.peardeck.com/",
cost: "Subscription",
},
{
id: "162",
category: "Side Hustle Categories",
subcategory: "Freelance and Remote Jobs",
name: "People per Hour",
description: "PeoplePerHour connects clients to expert freelancers who are available to hire by the hour or project.",
url: "https://www.peopleperhour.com/",
cost: "Paid",
},
{
id: "163",
category: "Visual Tools",
subcategory: "Stock Photos",
name: "Pexels",
description: "Use Pexels for free stock photos & videos you can use everywhere. Browse millions of high-quality royalty free stock images & copyright free pictures.",
url: "https://www.pexels.com/",
cost: "Free",
},
{
id: "164",
category: "AI",
subcategory: "Photo Enhancment",
name: "Photo Booth",
description: "Create stunning profile pictures with AI.",
url: "https://magicstudio.com/photobooth",
cost: "Paid",
},
{
id: "165",
category: "AI",
subcategory: "Photo Enhancment",
name: "Photo Editing - HotPot.AI",
description: "Hotpot.ai is an easy way to create device mockups, logos, social media ads, and other graphics.",
url: "https://hotpot.ai/",
cost: "Paid",
},
{
id: "166",
category: "Visual Tools",
subcategory: "Photo Editing and Tools",
name: "Photo Kit",
description: "PhotoKit  can fetch and cache assets for display and playback, edit image and video content, or manage collections of assets such as albums, Moments, and Shared Albums.",
url: "https://photokit.com/",
cost: "Paid",
},
{
id: "167",
category: "Visual Tools",
subcategory: "Photo Editing and Tools",
name: "Photopea",
description: "Photopea is an Online Photo Editor that lets you edit photos, apply effects, filters, add text, crop or resize pictures.",
url: "https://photopea.com",
cost: "Free",
},
{
id: "168",
category: "Income Generator Platform",
subcategory: "E-Commerce",
name: "Piertra",
description: "Pietra is a platform for creators who have an idea for a brand or product line and need a place to start.",
url: "https://pietrastudio.com/",
cost: "Percentage",
},
{
id: "169",
category: "Visual Tools",
subcategory: "Photo Editing and Tools",
name: "Pinetools",
description: "Free online tools for every situation, work with text (reverse, convert to uppercase or lowercase), images (invert, resize, crop), lists (sort, count, randomize).",
url: "https://pinetools.com/",
cost: "Free",
},
{
id: "170",
category: "Side Hustle Categories",
subcategory: "Side Hustle Lists",
name: "Pinterest Business",
description: "Pinterest business account allows you to grow your business on one of the top platforms for discovery and decisions.",
url: "https://www.pinterest.ca/convert-business/",
cost: "Paid",
},
{
id: "171",
category: "Work Help",
subcategory: "Presentation Help",
name: "Pix Teller",
description: "Pixteller is an online design tool to create or customize photos for social media, digital ads, websites and blogs, print materials, covers and headers, business and more.",
url: "https://pixteller.com/",
cost: "Subscription",
},
{
id: "172",
category: "Visual Tools",
subcategory: "Photo Editing and Tools",
name: "Pixel Hunter",
description: "Pixelhunter is a smart image resizer for media publications.",
url: "https://pixelhunter.io/",
cost: "Paid",
},
{
id: "173",
category: "Visual Tools",
subcategory: "Photo Editing and Tools",
name: "Pixelz",
description: "Pixelz is a photo editing service, with same day delivery. It offers background removal & image retouching for professionals.",
url: "https://www.pixelz.com/",
cost: "Free",
},
{
id: "174",
category: "Visual Tools",
subcategory: "Photo Editing and Tools",
name: "Pixlr",
description: "Pixlr is a photo editor that makes it easy for anyone to make and share creative images. Pixlr users can quickly crop, rotate, and fine-tune pictures in a completely ad-free experience.",
url: "https://pixlr.com/",
cost: "Paid",
},
{
id: "175",
category: "Visual Tools",
subcategory: "Photo Entertainment",
name: "Pixton",
description: "Pixton is a comic creation site for classrooms where teachers and students can both make and share their comic strips.",
url: "https://www.pixton.com/",
cost: "Free",
},
{
id: "176",
category: "Income Generator Platform",
subcategory: "Health & Fitness",
name: "Playbook",
description: "Playbookapp is a marketplace where health, wellness and fitness creators share and monetize their freshest content with their clients, fans and followers.",
url: "https://playbookapp.io/",
cost: "Percentage",
},
{
id: "177",
category: "Work Help",
subcategory: "Productivity Tools",
name: "Pocket",
description: "Pocket is an application and web service for managing a reading list of articles and videos from the Internet.",
url: "https://getpocket.com/",
cost: "Paid",
},
{
id: "178",
category: "Work Help",
subcategory: "Collaboration",
name: "Poll Everywhere",
description: "Poll Everywhere is a dynamic online polling platform that allows students to vote on custom teacher-generated polls through text messaging (SMS), smartphone, or computer by visiting its website.",
url: "https://www.polleverywhere.com/",
cost: "Subscription",
},
{
id: "179",
category: "Side Hustle Categories",
subcategory: "Web Site Creator",
name: "Populr",
description: "Populr is a professional communication tool that allows you to easily create & share trackable, one page websites via a drag and drop interface.",
url: "https://populr.me/",
cost: "Subscription",
},
{
id: "180",
category: "Work Help",
subcategory: "Productivity Tools",
name: "Portfolium",
description: "Portfolium is an academic portfolio network allowing you to showcase your education and work accomplishments and connect with other professionals.",
url: "https://portfolium.com/",
cost: "Paid",
},
{
id: "181",
category: "Work Help",
subcategory: "Presentation Help",
name: "Poster My Wall",
description: "PosterMyWall is a one-stop online solution for all your graphic design needs.",
url: "https://www.postermywall.com/",
cost: "Subscription",
},
{
id: "182",
category: "Work Help",
subcategory: "Presentation Help",
name: "Pow Toon",
description: "Powtoon is the visual communication platform that gives you the freedom to create professional and fully customized videos.",
url: "https://www.powtoon.com/",
cost: "Subscription",
},
{
id: "183",
category: "Work Help",
subcategory: "Productivity Tools",
name: "PR Web",
description: "PRWeb is a leading online news and press release distribution service.",
url: "https://www.prweb.com/",
cost: "Paid",
},
{
id: "184",
category: "Work Help",
subcategory: "Presentation Help",
name: "Prezi",
description: "Prezi is a web- based tool that allows users to create a presentation using a map layout. They are able to zoom in and out of different items and show relationships from an interesting point of view.",
url: "https://prezi.com/",
cost: "Subscription",
},
{
id: "185",
category: "Income Generator Platform",
subcategory: "E-Commerce",
name: "Printify",
description: "printify.com is a site to sell custom t-shirts, phone cases, and 300+ products with your designs printed on demand.",
url: "https://printify.com",
cost: "Percentage",
},
{
id: "186",
category: "Side Hustle Categories",
subcategory: "Book Writing",
name: "Pro Writing Aid",
description: "ProWritingAid is a writing app editing tool that analyzes your text and highlights a variety of key writing issues, such as overused words, sentence structure, punctuation issues, repeated phrases, consistency, dialogue, pacing and readability.",
url: "https://prowritingaid.com/",
cost: "Subscription",
},
{
id: "187",
category: "Side Hustle Categories",
subcategory: "Marketing Tools",
name: "Product Hunt",
description: "Product Hunt is a curation of the best new products, every day. Discover the latest mobile apps, websites, and technology products that everyone's talking about.",
url: "https://www.producthunt.com/",
cost: "Free",
},
{
id: "188",
category: "Work Help",
subcategory: "Productivity Tools",
name: "Profile Pic Maker",
description: "PFPMaker generates professional/creative profile pics from any photo.",
url: "https://pfpmaker.com/",
cost: "Paid",
},
{
id: "189",
category: "Side Hustle Categories",
subcategory: "Marketing Tools",
name: "Quara",
description: "Quora is a place to gain and share knowledge. It's a platform to ask questions and connect with people who contribute unique insights and quality answers.",
url: "https://www.quora.com/",
cost: "Free",
},
{
id: "190",
category: "Side Hustle Categories",
subcategory: "Marketing Tools",
name: "Quora",
description: "Quora is a social question-and-answer website that you can use for affiliate marketing.",
url: "https://www.quora.com/",
cost: "Free",
},
{
id: "191",
category: "Income Generator Platform",
subcategory: "E-Commerce",
name: "Rejoicy",
description: "Rejoicy is a community inspired platform designed to help local businesses set up digital storefronts to connect with their customers through online sales.",
url: "https://home.rejoicy.com/",
cost: "Subscription",
},
{
id: "192",
category: "Side Hustle Categories",
subcategory: "Freelance and Remote Jobs",
name: "Remote Work - Indeed",
description: "Remote work job listing site at Indeed.com",
url: "https://ca.indeed.com/Remote-Work-From-Home-jobs",
cost: "Free",
},
{
id: "193",
category: "Side Hustle Categories",
subcategory: "Freelance and Remote Jobs",
name: "Remote Work - LinkedIn",
description: "Remote work job listing site at Linkedin.com",
url: "https://www.linkedin.com/jobs/work-from-home-jobs/",
cost: "Free",
},
{
id: "194",
category: "Side Hustle Categories",
subcategory: "Freelance and Remote Jobs",
name: "Remote Work - ZipRecruiter",
description: "Remote work job listing site at Ziprecruiter.com",
url: "https://www.ziprecruiter.com/Jobs/Remote-Work-From-Home",
cost: "Free",
},
{
id: "195",
category: "Visual Tools",
subcategory: "Photo Editing and Tools",
name: "Remove",
description: "Remove.bg is a web-based service to remove the background of any photo.",
url: "https://www.remove.bg/",
cost: "Free",
},
{
id: "196",
category: "Work Help",
subcategory: "Integration Software",
name: "Render",
description: "Render is a unified cloud to build and run all your apps and websites with free TLS certificates, global CDN, private networks and auto deploys from Git.",
url: "https://render.com/",
cost: "Subcription with Free Tier",
},
{
id: "197",
category: "Work Help",
subcategory: "Productivity Tools",
name: "Render Forest",
description: "Renderforest is a free online animated video and slideshow maker",
url: "https://www.renderforest.com/",
cost: "Subscription",
},
{
id: "198",
category: "Income Generator Platform",
subcategory: "Services",
name: "Rev",
description: "Rev is a company that provides transcription, translation, and captioning services.",
url: "https://www.rev.com/",
cost: "Free",
},
{
id: "199",
category: "Amazon",
subcategory: "Amazon Tools and Utilities",
name: "Review Meta",
description: "ReviewMeta is a tool designed for Amazon shoppers. It evaluates reviews on products you are looking at and decides which reviews are genuine and which are not. It then will remove fake reviews and give the product a new score.",
url: "https://reviewmeta.com/",
cost: "Free",
},
{
id: "200",
category: "Income Generator Platform",
subcategory: "Services",
name: "Rover",
description: "Rover.com is an online marketplace for people to buy and sell pet care services including pet sitting, dog boarding, and dog walking.",
url: "https://www.rover.com",
cost: "Percentage",
},
{
id: "201",
category: "Visual Tools",
subcategory: "Video Making & Editing",
name: "Run Way ML",
description: "Runway is the Swiss army knife of machine learning. It lets you rapidly prototype your machine learning projects.",
url: "https://runwayml.com/",
cost: "Paid",
},
{
id: "202",
category: "AI",
subcategory: "Text Enhancement",
name: "Rytr",
description: "Rytr is an AI writing assistant that helps you create high-quality content, in just a few seconds, at a fraction of the cost! ; killer content, effortlessly.",
url: "https://rytr.me/",
cost: "Subcription with Free Tier",
},
{
id: "203",
category: "Income Generator Platform",
subcategory: "Health & Fitness",
name: "Salut",
description: "Salut is a live video streaming app that helps fitness instructors broadcast classes to virtual audiences and get paid with donations.",
url: "https://www.joinsalut.com/",
cost: "",
},
{
id: "204",
category: "Work Help",
subcategory: "Productivity Tools",
name: "Screen Capture",
description: "screencapture.com is an online screen recorder.",
url: "https://www.screencapture.com/",
cost: "Paid",
},
{
id: "205",
category: "Work Help",
subcategory: "Productivity Tools",
name: "See Saw",
description: "Seesaw is a platform for student engagement. Teachers can empower students to create, reflect, share, and collaborate.",
url: "https://web.seesaw.me/",
cost: "Paid",
},
{
id: "206",
category: "Income Generator Platform",
subcategory: "E-Commerce",
name: "Selar",
description: "Selar is an all-in-one e-commerce store builder to sell any kind of digital product or service to anyone, anywhere in the world.",
url: "https://selar.co/register",
cost: "Percentage",
},
{
id: "207",
category: "Work Help",
subcategory: "Productivity Tools",
name: "Sem Rush",
description: "Semrush is an all-in-one tool suite for improving online visibility and discovering marketing insights.",
url: "https://semrush.com",
cost: "Paid",
},
{
id: "208",
category: "Side Hustle Categories",
subcategory: "Affiliates",
name: "Sephora Affiliates",
description: "Referral affiliate site for Sephora",
url: "https://www.sephora.com/ca/en/beauty/affiliates",
cost: "Free",
},
{
id: "209",
category: "Income Generator Platform",
subcategory: "Services",
name: "Service Connection",
description: "Service Connection makes the process of finding someone to complete a project move faster.",
url: "https://serviceconnection.pro/Provider",
cost: "Paid",
},
{
id: "210",
category: "Income Generator Platform",
subcategory: "Services",
name: "Shef",
description: "Shef is a community-based platform that enables cooks to sell homemade food to their neighbors.",
url: "https://shef.com",
cost: "Percentage",
},
{
id: "211",
category: "Income Generator Platform",
subcategory: "E-Commerce",
name: "Shopify",
description: "Shopify is a subscription-based software that allows anyone to set up an online store and sell their products.",
url: "https://www.shopify.com",
cost: "Subscription",
},
{
id: "212",
category: "Side Hustle Categories",
subcategory: "Side Hustle Lists",
name: "Side Hustle Stack",
description: "Side Hustle Stack is a FREE resource to find platform-based work, ranging from gig work and side hustles to platforms that help you start a small business that can grow.",
url: "https://sidehustlestack.co/",
cost: "Paid",
},
{
id: "213",
category: "Side Hustle Categories",
subcategory: "Marketing Tools",
name: "Simplified",
description: "Simplified is the all-in-one design platform where freelancers and entrepreneurs can collaborate, scale, and grow their content.",
url: "https://simplified.co/",
cost: "Free",
},
{
id: "214",
category: "Side Hustle Categories",
subcategory: "Tutor",
name: "Skill Share",
description: "Skillshare is an online learning platform with thousands of creative courses and classes taught by experts to help you learn new skills.",
url: "https://www.skillshare.com/",
cost: "Paid",
},
{
id: "215",
category: "Side Hustle Categories",
subcategory: "Tutor",
name: "Skillshop with Google",
description: "Skillshop is a one-stop-shop training center for everyone who uses Google professional tools and solutions.",
url: "https://skillshop.withgoogle.com/",
cost: "Free",
},
{
id: "216",
category: "Visual Tools",
subcategory: "Photo Editing and Tools",
name: "Skylum LuminarAI",
description: "LuminarAI is an alternative to traditional photo editors in general. They offer a new approach to editing your images, powered by artificial intelligence.",
url: "https://skylum.com/luminar-ai-b",
cost: "Paid",
},
{
id: "217",
category: "Work Help",
subcategory: "Collaboration",
name: "Sli",
description: "Slido is an audience interaction platform for meetings and events.",
url: "https://www.sli.do/",
cost: "Subscription",
},
{
id: "218",
category: "Work Help",
subcategory: "Presentation Help",
name: "Slide Dog",
description: "SlideDog is a multimedia presentation tool that lets you combine PowerPoint presentations, PDF files, Prezi presentations, movie clips, web pages, and more into one innovative, seamless viewing and audience interaction experience.",
url: "https://slidedog.com/",
cost: "Subscription",
},
{
id: "219",
category: "Work Help",
subcategory: "Presentation Help",
name: "Slide Go",
description: "Slidesgo offers an extensive catalog of free Google Slides themes and PowerPoint templates for creative presentations. These are customizable to better suit the needs of any given user, so the possibilities are endless.",
url: "https://slidesgo.com/",
cost: "Subscription",
},
{
id: "220",
category: "Work Help",
subcategory: "Presentation Help",
name: "Slides Carnival",
description: "SlidesCarnival offer templates that have all the elements you need to effectively communicate your message and impress your audience.",
url: "https://www.slidescarnival.com/",
cost: "Paid",
},
{
id: "221",
category: "Work Help",
subcategory: "Productivity Tools",
name: "Small SEO Tools",
description: "Small SEO Tools has many SEO that are 100% free? Check out their Search Engine Optimization tools for checking backlinks, tracking keyword positions and their Plagiarism checker too.",
url: "https://smallseotools.com/",
cost: "Free",
},
{
id: "222",
category: "AI",
subcategory: "Photo Enhancment",
name: "Smart Draw AI - Autodraw",
description: "AutoDraw is a web-based tool that pairs machine learning with drawings created by talented artists to help you draw.",
url: "https://www.autodraw.com/",
cost: "Paid",
},
{
id: "223",
category: "Side Hustle Categories",
subcategory: "Marketing Tools",
name: "Social Blade",
description: "Social Blade gives all users access to our public database which, using advanced technology, is able to provide you with global analytics for any content.",
url: "https://socialblade.com/",
cost: "Free",
},
{
id: "224",
category: "Work Help",
subcategory: "Productivity Tools",
name: "SocialStudio",
description: "Social Studio is a personal Instagram assistant that will generate your content with Artificial Intelligence, allow you to design your posts, and schedule directly to Instagram in minutes.",
url: "https://www.socialstudio.ai/",
cost: "Paid",
},
{
id: "225",
category: "Work Help",
subcategory: "Collaboration",
name: "Socrative",
description: "Socrative is an interactive web-based student-response system (also available through iOS, Android, or Chrome apps) that can help teachers spark conversation.",
url: "https://www.socrative.com/",
cost: "Paid",
},
{
id: "226",
category: "Work Help",
subcategory: "Integration Software",
name: "Softr",
description: "Softr is an incredible web development solution that helps users generate code-free websites, portals, or web apps on Airtatble within just ten minutes.",
url: "https://www.softr.io/",
cost: "Paid",
},
{
id: "227",
category: "AI",
subcategory: "Speech Enhancement",
name: "Speechelo",
description: "Speechelo is a powerful text to speech software that creates human sounding voiceovers on demand.",
url: "https://speechelo.com/",
cost: "Paid",
},
{
id: "228",
category: "AI",
subcategory: "Photo Enhancment",
name: "Stable Diffusion",
description: "Stable Diffusion is a latent text-to-image diffusion model capable of generating photo-realistic images given any text input.",
url: "https://stablediffusionweb.com/",
cost: "Free",
},
{
id: "229",
category: "Work Help",
subcategory: "Integration Software",
name: "Stacker",
description: "Stacker is a software layer that allows users to create interactive front-end interfaces to their spreadsheet databases built on Airtable, Google Sheets or many other data sources.",
url: "https://stacker.io",
cost: "Paid",
},
{
id: "230",
category: "Work Help",
subcategory: "Collaboration",
name: "Standup Wizard",
description: "Standup Wizard uses the power of SLACK to create asynchronous standups. Remote teams can report status when convenient. Lets your teams become more effective and efficient.",
url: "https://standupwizard.com/",
cost: "Paid",
},
{
id: "231",
category: "Side Hustle Categories",
subcategory: "Marketing Tools",
name: "Starter Story",
description: "Starter Story is a media company covering entrepreneurship that got their start by interviewing successful entrepreneurs and sharing the stories behind their businesses. Find this and many tools on this site.",
url: "https://www.starterstory.com/",
cost: "Paid",
},
{
id: "232",
category: "Visual Tools",
subcategory: "Stock Photos",
name: "Stock Photos - Pexels.com",
description: "Pexels is a free stock photo and video website and app that helps designers, bloggers, and everyone who is looking for visuals to find great photos and videos that can be downloaded and used for free.",
url: "https://www.pexels.com/",
cost: "Paid",
},
{
id: "233",
category: "Visual Tools",
subcategory: "Video Making & Editing",
name: "Stop Motion",
description: "Stop Motion Studio is a powerful, full-featured movie editor with a whole host of features.",
url: "https://play.google.com/store/apps/details?id=com.cateater.stopmotionstudio&hl=en_CA&gl=US",
cost: "Subscription",
},
{
id: "234",
category: "Income Generator Platform",
subcategory: "Services",
name: "Store Tasker",
description: "Storetasker is an online marketplace focused on connecting Shopify merchants with developers and other experts who can help grow their business.",
url: "https://www.storetasker.com",
cost: "Percentage",
},
{
id: "235",
category: "AI",
subcategory: "Text Summarization & Copy",
name: "Summary Text Smmry",
description: "SMMRY summarizes articles and text which is done primarily by reducing the text to only the most important sentences.",
url: "https://smmry.com/",
cost: "Free",
},
{
id: "236",
category: "Income Generator Platform",
subcategory: "Health & Fitness",
name: "Super Fit",
description: "SuperFit helps solo trainers build workouts programs and organize their fitness clients & groups.",
url: "https://superfitapp.com",
cost: "Percentage",
},
{
id: "237",
category: "Income Generator Platform",
subcategory: "Health & Fitness",
name: "Super Set",
description: "supersetapp.com lets you create and sell programs, challenges, 1-on-1 training, and live classes, all within your own app-like experience.",
url: "https://www.supersetapp.com",
cost: "Percentage",
},
{
id: "238",
category: "Work Help",
subcategory: "Productivity Tools",
name: "Synthesia",
description: "Synthesia.io is an AI video creation platform developed for entrepreneurs, business owners, and corporates without the need for actors.",
url: "https://www.synthesia.io/",
cost: "Paid",
},
{
id: "239",
category: "Work Help",
subcategory: "Productivity Tools",
name: "Take a Five",
description: "Takeafive.com is a simple productivity tool to remind you to take a break.",
url: "http://www.takeafive.com/",
cost: "Free",
},
{
id: "240",
category: "AI",
subcategory: "Google AI",
name: "Talk to Books",
description: "Talk to Books by Google is a new way to explore ideas and discover books. Make a statement or ask a question to browse passages from books using experimental AI.",
url: "https://books.google.com/talktobooks/",
cost: "Free",
},
{
id: "241",
category: "Work Help",
subcategory: "Productivity Tools",
name: "Talkia",
description: "Talkia is software that can be used to convert any text to a lifelike sounding voiceover for videos, ads, audio books, and more.",
url: "https://www.talkia.com/",
cost: "Paid",
},
{
id: "242",
category: "Income Generator Platform",
subcategory: "Services",
name: "Task Rabbit",
description: "TaskRabbit is an online and mobile marketplace that matches freelance labor with local demand, allowing consumers to find immediate help with everyday tasks, including cleaning, moving, delivery and handyman work.",
url: "https://www.taskrabbit.com",
cost: "",
},
{
id: "243",
category: "Income Generator Platform",
subcategory: "E-Commerce",
name: "TeeSpring",
description: "Teespring is a free platform that lets you create and sell over 50 kinds of products with no upfront cost or risk. They handle everything, from printing to shipping to customer service.",
url: "https://teespring.com/",
cost: "Paid",
},
{
id: "244",
category: "Work Help",
subcategory: "Email tools",
name: "Temp Mail",
description: "TempMail is a free email service that allows to receive email at a temporary address that self-destructs after a certain time elapses.",
url: "https://temp-mail.org/en/",
cost: "Paid",
},
{
id: "245",
category: "Side Hustle Categories",
subcategory: "Affiliates",
name: "Tesla Referral",
description: "Referral affiliate site for Tesla",
url: "https://www.tesla.com/support/referral-program",
cost: "Free",
},
{
id: "246",
category: "Side Hustle Categories",
subcategory: "Software Testing",
name: "Testing Time",
description: "Testing Time is a usability testing site that pays users to test out new products, complete studies/surveys, diary studies and more.",
url: "https://www.testingtime.com/en/",
cost: "Paid",
},
{
id: "247",
category: "Side Hustle Categories",
subcategory: "Music",
name: "The Micco",
description: "MICCO is a place to promote your music, grow your brand & build relationships.",
url: "https://www.themicco.com/",
cost: "Paid",
},
{
id: "248",
category: "Side Hustle Categories",
subcategory: "Music",
name: "The Unsigned Guide",
description: "The Unsigned Guide is an online contacts directory and careers guide for the UK music industry",
url: "https://www.theunsignedguide.com",
cost: "Subscription",
},
{
id: "249",
category: "AI",
subcategory: "Google AI",
name: "Thing Translator",
description: "This experimental tool by Google lets you take a picture of something to hear how to say it in a different language.",
url: "https://experiments.withgoogle.com/thing-translator",
cost: "Free",
},
{
id: "250",
category: "AI",
subcategory: "Text Enhancement",
name: "TikTok Tag Generator",
description: "Tiktokhashtags.com is a tool the find the best TikTok Hashtags to get more eyes on your videos.",
url: "https://tiktokhashtags.com/",
cost: "Free",
},
{
id: "251",
category: "Visual Tools",
subcategory: "Photo Editing and Tools",
name: "Tiny png",
description: "Tinypng allows you to scale, preview and save compressed PNG and JPEG images straight from Photoshop or your computer.",
url: "https://tinypng.com/",
cost: "Free",
},
{
id: "252",
category: "Visual Tools",
subcategory: "Video Making & Editing",
name: "Toonly",
description: "Toonly is an animated explainer video creator that you can use to create simple videos to provide instruction on how to do something, marketing for a product or service, and more.",
url: "https://www.toonly.com/",
cost: "Paid",
},
{
id: "253",
category: "Work Help",
subcategory: "Collaboration",
name: "Tricider",
description: "Tricider is a website that offers a free brainstorming and voting platform to help people make decisions.",
url: "https://www.tricider.com/",
cost: "Paid",
},
{
id: "254",
category: "Side Hustle Categories",
subcategory: "Software Testing",
name: "Try My UI",
description: "Become a TryMyUI tester and get paid to use websites and apps and give your honest feedback!",
url: "https://trymyui.com/",
cost: "Paid",
},
{
id: "255",
category: "Twitter",
subcategory: "Twitter Tools and Utilities",
name: "Twitter Post Capture - Poet.so",
description: "Poet.so makes sharing Twitter posts on other platforms more visual and attention-grabbing.",
url: "https://poet.so/",
cost: "Free",
},
{
id: "256",
category: "Income Generator Platform",
subcategory: "Services",
name: "Uber",
description: "Uber is a platform where those who drive and deliver can connect with riders, eaters, and restaurants.",
url: "https://www.uber.com/",
cost: "Percentage",
},
{
id: "257",
category: "Visual Tools",
subcategory: "Stock Photos",
name: "UnDraw",
description: "Undraw has open-source illustrations for any idea you can imagine and create.",
url: "https://undraw.co/",
cost: "Paid",
},
{
id: "258",
category: "Work Help",
subcategory: "Productivity Tools",
name: "Unicorn Ads",
description: "Unicornads.com/oet.so is an Interactive E-commerce Ad Library for Facebook and Instagram ads - Includes all online ads from the top 1.000 Shopify Stores on Facebook & Instagram.",
url: "https://www.unicornads.com/",
cost: "Paid",
},
{
id: "259",
category: "Visual Tools",
subcategory: "Video Making & Editing",
name: "Unscreen",
description: "Unscreen is an online platform that enables its users to remove the background in a video.",
url: "https://www.unscreen.com/",
cost: "Free",
},
{
id: "260",
category: "Visual Tools",
subcategory: "Stock Photos",
name: "UnSplash",
description: "Unsplash is a site for beautiful, free images and photos that you can download and use for any project. Better than any royalty free or stock photos.",
url: "https://unsplash.com/",
cost: "Free",
},
{
id: "261",
category: "Side Hustle Categories",
subcategory: "Freelance and Remote Jobs",
name: "Upwork",
description: "Upwork is a marketplace for freelancers in fields like writing, graphic design and web development.",
url: "https://www.upwork.com/",
cost: "Paid",
},
{
id: "262",
category: "Side Hustle Categories",
subcategory: "Software Testing",
name: "User Feel",
description: "Userfeel is a cloud-based usability testing tool which assists digital agencies and UX researchers with website and app optimization with key features including multilingual support, user journeys, screen activity recording, multi-channel collection, campaign segmentation, and video annotation.",
url: "https://www.userfeel.com/",
cost: "Paid",
},
{
id: "263",
category: "Side Hustle Categories",
subcategory: "Freelance and Remote Jobs",
name: "User Testing",
description: "UserTesting is a platform for getting rapid customer feedback on almost any customer experience you can imagine, including websites, mobile apps, prototypes, and real world experiences.",
url: "https://www.usertesting.com/",
cost: "Free",
},
{
id: "264",
category: "Side Hustle Categories",
subcategory: "Software Testing",
name: "Userlytics",
description: "Userlytics is a state of the art user testing platform that allows you to quickly run user experience tests on any digital asset (sites, apps, prototypes, competitor assets, or more) and on any device.",
url: "https://www.userlytics.com/",
cost: "Paid",
},
{
id: "265",
category: "Work Help",
subcategory: "Productivity Tools",
name: "Venn Gage",
description: "Venngage is an online tool for creating infographics, reports, and data visualizations.",
url: "https://venngage.com/",
cost: "Subscription",
},
{
id: "266",
category: "Work Help",
subcategory: "Integration Software",
name: "Vercel",
description: "Vercel allows you to deploy instantly, scale automatically, and serve personalized content around the globe. Seamlessly integrate your headless CMS, commerce, or database for faster builds. Supports 30+ frameworks. Production-grade staging. Global Edge network. Real-time insights.",
url: "https://vercel.com/",
cost: "Subcription with Free Tier",
},
{
id: "267",
category: "Visual Tools",
subcategory: "Video Making & Editing",
name: "Video Editing - Veed.io",
description: "Veed is a simple but powerful video editor to resize video, trim video add loads more!",
url: "https://www.veed.io/",
cost: "Paid",
},
{
id: "268",
category: "Visual Tools",
subcategory: "Video Making & Editing",
name: "Video Leap App",
description: "Videoleap is a fun & powerful video editing app. Combine clips, add text & artistic effects, and edit videos on your iPhone & iPad.",
url: "https://apps.apple.com/us/app/videoleap-editor-by-lightricks/id1255135442",
cost: "Paid",
},
{
id: "269",
category: "AI",
subcategory: "Cool AI Stuff",
name: "Voice Mod",
description: "Voicemod is the best free voice changing software for gamers, content creators, and vtubers. Add a AI voice modifier to sound like a robot, demon, chipmunk and more.",
url: "https://www.voicemod.net/",
cost: "Free",
},
{
id: "270",
category: "Side Hustle Categories",
subcategory: "Voice Work",
name: "Voices",
description: "Voices is a job search website focusing on voice actors and voice actor employers.",
url: "https://www.voices.com/",
cost: "Free",
},
{
id: "271",
category: "Income Generator Platform",
subcategory: "Services",
name: "Wag!",
description: "Wag pet care company that offers a technology platform to enable on-demand and scheduled dog walking, training, and other petcare services through a mobile application.",
url: "https://wagwalking.com",
cost: "Percentage",
},
{
id: "272",
category: "AI",
subcategory: "Photo Enhancment",
name: "Watermark Remover",
description: "Aiseesoft Watermark Remover has the capability to delete all watermarks from photos.",
url: "https://www.aiseesoft.com/watermark-remover-online/",
cost: "Free",
},
{
id: "273",
category: "Side Hustle Categories",
subcategory: "Book Writing",
name: "Way With Words",
description: "Way With Words provides 99%+ accurate audio and video transcription, perfectly synced closed captioning and custom audio to text solutions.",
url: "https://waywithwords.net/",
cost: "Free",
},
{
id: "274",
category: "Side Hustle Categories",
subcategory: "Web Site Creator",
name: "Weebly",
description: "Weebly is a website and eCommerce service that makes it easy to build a professional, mobile-optimized site and grow your business with integrated marketing and advanced analytics.",
url: "https://www.weebly.com/",
cost: "Subscription",
},
{
id: "275",
category: "Side Hustle Categories",
subcategory: "Web Site Creator",
name: "Wix",
description: "Wix.com provides cloud-based web development services and allows users to create HTML5 websites and mobile sites through the use of online drag and drop tools.",
url: "https://www.wix.com/",
cost: "Subscription",
},
{
id: "276",
category: "Income Generator Platform",
subcategory: "Services",
name: "Wonolo",
description: "Wonolo is an on-demand staffing platform that connects job seekers and businesses looking to fill their labor needs.",
url: "https://www.wonolo.com",
cost: "Free",
},
{
id: "277",
category: "Work Help",
subcategory: "Collaboration",
name: "Woo Clap",
description: "Wooclap is an interactive platform that transforms smartphones into exceptional learning tools to make learning awesome and effective.",
url: "https://www.wooclap.com/",
cost: "Subscription",
},
{
id: "278",
category: "AI",
subcategory: "Text Enhancement",
name: "WordTune",
description: "Diversify your langue, level-up your writing, and say goodbye to grammatical errors. A free tool that works anywhere you do. Advanced AI Tools. Founded In 2018. Add To Chrome For Free.",
url: "https://www.wordtune.com/",
cost: "Free",
},
{
id: "279",
category: "Work Help",
subcategory: "Collaboration",
name: "YO Teach App",
description: "YoTeachApp is a great backchannel site to utilize with students and teachers.",
url: "https://yoteachapp.com/",
cost: "Paid",
},
];
import { Helmet } from "react-helmet";
import React, { useEffect, useState } from "react";

import Container from "../components/container";
import Password from "../components/password";
import WebsiteDisplay from "../components/website-display";
import SidehustleDisplay from "../components/sidehustle-display";
import Tabs from "../components/tabs";

const IndexPage = () => {
  const [showData, setShowData] = useState(false);
  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    //const hasAccess = localStorage.getItem("hasAccess");
    const hasAccess = sessionStorage.getItem("hasAccess");
    if (hasAccess) setShowData(true);
  }, []);

  const handleShowData = () => {
    setShowData(true);
    //localStorage.setItem("hasAccess", "true");
    sessionStorage.setItem("hasAccess", "true");
  };

  console.log({ activeTab });

  return (
    // removed the 'min-h-screen' to remove the centering of the table
    <>
      <Helmet>
        <title>Data - Side Hustle Tool</title>
      </Helmet>
      {showData ? (
        <div className="bg-gray-100 min-w-screen  flex items-center justify-center">
          <Container>
            <div className="shadow rounded-lg w-full">
              <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
              <div className="p-8 bg-white rounded-b-lg">
                {activeTab === 1 ? <WebsiteDisplay /> : <SidehustleDisplay />}
              </div>
            </div>
          </Container>
        </div>
      ) : (
        <div className="bg-gray-100 min-w-screen min-h-screen  flex items-center justify-center">
          <Password onShowData={handleShowData} />
        </div>
      )}
    </>
  );
};

export default IndexPage;

import React, { useState } from "react";

import Button from "../button";
import Input from "../input";

const ACCESS_PASSWORD = ["123x","9wRtrD8JTH4V", "10wRtrDJH67V","11Grx4Ty"];

const Password = ({ onShowData }) => {
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);

  //if password is correct.. show data.. otherwise show error
  const handlePasswordCheck = () => {
    if ( ACCESS_PASSWORD.includes(password)) {
      onShowData();
      setError(false);
    } else {
      setError(true);
    }
  };

  return (
    <div className="bg-white overflow-hidden shadow rounded-lg px-10 py-8">
      <div className="px-4 py-5 sm:p-6">
        <Input
          type="password"
          id="password"
          label="Please enter your access password:"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          error={error}
        />
        {error && (
          <p class="mt-2 text-sm text-red-600" id="email-error">
            Incorrect access password.
          </p>
        )}
        <div className="mt-3">
          <Button
            text="Side Hustle Tool Access"
            onClick={handlePasswordCheck}
            fullWidth
          />
        </div>
      </div>
    </div>
  );
};

export default Password;

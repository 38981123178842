import Fuse from "fuse.js";
import React, { useEffect, useState } from "react";

import { sidehustleData } from "../../data/sidehustles";
// // for panel
// import { sidehustleSitesData } from "../../data/sidehustlesites";

import { sidehustleCategories } from "../../data/categories";
import { sidehustleTypes } from "../../data/categories";

import Dropdown from "../dropdown";
import Input from "../input";
import Table from "../sidehustle-table";

// for panel
import Panel from "../panel";

// for alert
import Alert from "../alert";

const WebsiteDisplay = () => {
  const [data, setData] = useState(sidehustleData);
  const [category, setCategory] = useState(sidehustleCategories[0]);
  const [type, setType] = useState(sidehustleTypes[0]);
  const [search, setSearch] = useState("");

  // for panel
  const [isOpen, setOpen] = useState(false);
  const [selectedSideHustle, setSelectedSideHustle] = useState(false);

  // for alert
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [selectedLink, setSelectedLink] = useState(false);

  useEffect(() => {
    let newData = sidehustleData;

    if (category !== sidehustleCategories[0]) {
      newData = newData.filter((d) => d.category === category);
    }

    if (type !== sidehustleTypes[0]) {
      newData = newData.filter((d) => d.type === type);
    }

    // May need to debounce
    if (search.length >= 3) {
      const fuse = new Fuse(newData, {
        threshold: 0,
        ignoreLocation: true,
        keys: ["name", "creator"],
      });
      const searchResults = fuse.search(search);
      newData = searchResults.map((result) => result.item);
    }

    setData(newData);
  }, [category, type, search]);

  // for panel
  const onRowSelect = (sideHustle) => {
    setOpen(true);
    setSelectedSideHustle(sideHustle);
  };

  //for Alert
  const onLinkSelect = (selectedLink) => {
    setAlertOpen(true);
    setSelectedLink(selectedLink);
  };

  const handleCategoryChange = (newCategory) => {
    setCategory(newCategory);
  };

  const handleTypeChange = (newType) => {
    setType(newType);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleClearFilters = () => {
    setCategory(sidehustleCategories[0]);
    setType(sidehustleTypes[0]);
    setData(sidehustleData);
    setSearch("");
  };

  const showClear =
    category !== sidehustleCategories[0] ||
    type !== sidehustleTypes[0] ||
    search.length;

  return (
    <div>
      <Panel
        open={isOpen}
        setOpen={setOpen}
        selectedSideHustle={selectedSideHustle}
      />
      <Alert
        openAlert={isAlertOpen}
        setAlertOpen={setAlertOpen}
        selectedLink={selectedLink}
      />
      <div className="pb-4">
        <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
          <div className="ml-4 mt-4">
            <div className="flex items-center">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Showing {data.length} of {sidehustleData.length} Side Hustle(s)
              </h3>
            </div>
          </div>
          <div className="ml-4 mt-4 flex-shrink-0 flex">
            {!!showClear && (
              <button
                className="flex items-center text-blue-500 text-xs font-bold mr-4 cursor-pointer"
                onClick={handleClearFilters}
              >
                Clear Filters
              </button>
            )}
            <span className="mr-4 hidden lg:block">
              <Dropdown
                onChange={handleCategoryChange}
                options={sidehustleCategories}
                value={category}
                width={250}
              />
            </span>
            <span className="mr-4 hidden xl:block">
              <Dropdown
                onChange={handleTypeChange}
                options={sidehustleTypes}
                value={type}
              />
            </span>
            <Input
              placeholder="Search Sidehustles"
              value={search}
              onChange={handleSearch}
            />
          </div>
        </div>
      </div>
      <div>
        <Table
          data={data}
          onRowSelect={onRowSelect}
          onLinkSelect={onLinkSelect}
        />
      </div>
    </div>
  );
};

export default WebsiteDisplay;

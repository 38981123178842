import Fuse from "fuse.js";
import React, { useEffect, useState } from "react";

import { websiteData } from "../../data/websites";
import { websiteCategories, websiteCosts } from "../../data/categories";

import Dropdown from "../dropdown";
import Input from "../input";
import Table from "../website-table";

// for alert
import Alert from "../alert";

const WebsiteDisplay = () => {
  const [data, setData] = useState(websiteData);
  const [category, setCategory] = useState(websiteCategories[0]);
  const [cost, setCost] = useState(websiteCosts[0]);
  const [search, setSearch] = useState("");

// for alert
const [isAlertOpen, setAlertOpen] = useState(false);
const [selectedLink,setSelectedLink] = useState(false);

  useEffect(() => {
    let newData = websiteData;

    if (category !== websiteCategories[0]) {
      newData = newData.filter((d) => d.category === category);
    }

    if (cost !== websiteCosts[0]) {
      newData = newData.filter((d) => d.cost === cost);
    }

    // May need to debounce
    if (search.length >= 3) {
      const fuse = new Fuse(newData, {
        threshold: 0,
        ignoreLocation: true,
        keys: ["description"],
      });
      const searchResults = fuse.search(search);
      newData = searchResults.map((result) => result.item);
    }

    setData(newData);
  }, [cost, category, search]);

  //for Alert
const onLinkSelect = (selectedLink) =>{
  setAlertOpen(true);
  setSelectedLink(selectedLink);
}

  const handleCategoryChange = (newCategory) => {
    setCategory(newCategory);
  };

  const handleCostChange = (newCost) => {
    setCost(newCost);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleClearFilters = () => {
    setCategory(websiteCategories[0]);
    setCost(websiteCosts[0]);
    setData(websiteData);
    setSearch("");
  };

  const showClear =
    category !== websiteCategories[0] ||
    cost !== websiteCosts[0] ||
    search.length;

  return (
    <div>
      <Alert openAlert={isAlertOpen} setAlertOpen={setAlertOpen} selectedLink={selectedLink}/>
      <div className="pb-4">
        <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
          <div className="ml-4 mt-4">
            <div className="flex items-center">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Showing {data.length} of {websiteData.length} Tool(s)
              </h3>
            </div>
          </div>
          <div className="ml-4 mt-4 flex-shrink-0 flex">
            {!!showClear && (
              <button
                className="flex items-center text-blue-500 text-xs font-bold mr-4 cursor-pointer"
                onClick={handleClearFilters}
              >
                Clear Filters
              </button>
            )}
            <span className="mr-4 hidden lg:block">
              <Dropdown
                onChange={handleCategoryChange}
                options={websiteCategories}
                value={category}
                width={250}
              />
            </span>
            <span className="mr-4 hidden lg:block">
              <Dropdown
                onChange={handleCostChange}
                options={websiteCosts}
                value={cost}
              />
            </span>
            <Input
              placeholder="Search Websites"
              value={search}
              onChange={handleSearch}
            />
          </div>
        </div>
      </div>
      <div>
        <Table data={data} onLinkSelect={onLinkSelect} />
      </div>
    </div>
  );
};

export default WebsiteDisplay;

import React from "react";

import { sidehustleColorMap } from "../../data/categories";

const SidehustleTable = ({ data,onRowSelect,onLinkSelect }) => {
  return (
    <div className="shadow overflow-x-auto border-b border-gray-200 sm:rounded-lg">
      <table className="table-fixed w-full divide-y divide-gray-200 whitespace-nowrap">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-2/12">
              Name
            </th>
            <th className="hidden xl:table-cell px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/12">
              Type
            </th>
            <th className="hidden lg:table-cell px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-2/12">
              Category
            </th>
            <th className="hidden xl:table-cell px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-2/12">
              Subcategory
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/12">
              Video
            </th>
            <th className="hidden md:table-cell px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/12">
              Creator
            </th>
            <th className="hidden md:table-cell px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/12">
              Site(s)
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {data.map((d) => (
            <tr key={d.id}>
              <td className="px-6 py-4 whitespace-pre-wrap text-md font-medium text-gray-900">
                {d.name}
              </td>
              <td className="hidden xl:table-cell px-6 py-4 whitespace-pre-wrap text-md font-medium text-gray-900">
                {d.type}
              </td>
              <td className="hidden lg:table-cell px-6 py-4 whitespace-pre-wrap text-sm text-gray-500">
                <span
                  className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                    sidehustleColorMap[d.category]
                  }`}
                >
                  {d.category}
                </span>
              </td>
              <td className="hidden xl:table-cell px-6 py-4 whitespace-pre-wrap text-sm text-gray-500">
                {d.subcategory}
              </td>
              <td className="px-6 py-4 whitespace-pre-wrap text-sm text-gray-500">
                <a
                  href={d.video}
                  className="text-indigo-600 hover:text-indigo-900 text-left"
                  target="_blank"
                  rel="noreferrer"
                >
                  Watch video
                </a>
              </td>
              <td onClick={() => onLinkSelect(d.creatorUrl)} className="hidden md:table-cell px-6 py-4 whitespace-pre-wrap text-sm">
              <button
                  
                  className="text-indigo-600 hover:text-indigo-900 text-left"

                >
                  {d.creator}
                </button>                
              </td>
              <td onClick={() => onRowSelect(d.link)} className="hidden md:table-cell px-6 py-4 whitespace-pre-wrap text-sm">
                <button
                  
                  className="text-indigo-600 hover:text-indigo-900 text-left"

                >
                  Site Links
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {!data.length && (
        <div className="p-5 text-center text-gray-700 border-t border-gray-200">
          No results
        </div>
      )}
    </div>
  );
};

export default SidehustleTable;

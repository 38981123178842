export const sidehustleSitesData ={
1:[{"site":"Sem Rush","url":"https://semrush.com"}],
2:[{"site":"Side Hustle Stack","url":"https://sidehustlestack.co/"}],
3:[{"site":"Axiom","url":"https://axiom.ai/"}],
5:[{"site":"Canva","url":"https://www.canva.com/join/qwh-jbb-qxt"}],
6:[{"site":"Unscreen","url":"https://www.unscreen.com/"}],
7:[{"site":"Remove","url":"https://www.remove.bg/"}],
8:[{"site":"Esty","url":"https://www.etsy.com"},{"site":"Amazon Ignite","url":"https://ignite.amazon.com/#/"}],
9:[{"site":"ACX Voice Work","url":"https://www.acx.com/"}],
10:[{"site":"Userlytics","url":"https://www.userlytics.com/"},{"site":"Testing Time","url":"https://www.testingtime.com/en/"},{"site":"Enroll app","url":"https://enrollapp.com/"},{"site":"Try My UI","url":"https://trymyui.com/"},{"site":"User Feel","url":"https://www.userfeel.com/"}],
11:[{"site":"People per Hour","url":"https://www.peopleperhour.com/"},{"site":"Book Cover Design","url":"https://www.canva.com/join/qwh-jbb-qxt"},{"site":"Amazon Direct Publishing","url":"https://kdp.amazon.com/en_US/"}],
12:[{"site":"Nike Affiliates","url":"https://www.nike.com/gb/help/a/affiliate-program-eu"},{"site":"Sephora Affiliates","url":"https://www.sephora.com/ca/en/beauty/affiliates"},{"site":"Disney Affiliates","url":"https://www.shopdisney.com/affiliates.html"},{"site":"Tesla Referral","url":"https://www.tesla.com/support/referral-program"}],
13:[{"site":"Cambly Tutoring","url":"https://www.cambly.com/en/tutors?lang=en"}],
14:[{"site":"Link Tree","url":"https://linktr.ee/"},{"site":"Click Bank","url":"https://www.clickbank.com/"}],
15:[{"site":"Skillshop with Google","url":"https://skillshop.withgoogle.com/"},{"site":"Upwork","url":"https://www.upwork.com/"}],
16:[{"site":"Remote Work - Indeed","url":"https://ca.indeed.com/Remote-Work-From-Home-jobs"},{"site":"Remote Work - LinkedIn","url":"https://www.linkedin.com/jobs/work-from-home-jobs/"},{"site":"Remote Work - ZipRecruiter","url":"https://www.ziprecruiter.com/Jobs/Remote-Work-From-Home"}],
18:[{"site":"Profile Pic Maker","url":"https://pfpmaker.com/"}],
19:[{"site":"Photo Kit","url":"https://photokit.com/"}],
20:[{"site":"Panzoid","url":"https://panzoid.com/"}],
21:[{"site":"Video Editing - Veed.io","url":"https://www.veed.io/"}],
22:[{"site":"Instagram Posts CANVA.com","url":"https://www.canva.com/instagram-posts/templates/"}],
23:[{"site":"Instagram Posts CANVA.com","url":"https://www.canva.com/instagram-posts/templates/"}],
24:[{"site":"Stock Photos - Pexels.com","url":"https://www.pexels.com/"},{"site":"Click Bank","url":"https://www.clickbank.com/"},{"site":"Pinterest Business","url":"https://www.pinterest.ca/convert-business/"}],
25:[{"site":"PR Web","url":"https://www.prweb.com/"}],
26:[{"site":"Chip Bot Pylon","url":"https://getchipbot.com/features/pylons"}],
28:[{"site":"Render Forest","url":"https://www.renderforest.com/"}],
29:[{"site":"Contra","url":"https://contra.com/"},{"site":"Click a Snap","url":"https://www.clickasnap.com/"},{"site":"Kamua","url":"https://kamua.com/"},{"site":"Content Drips","url":"https://contentdrips.com/"},{"site":"Mock Magic","url":"https://www.mockmagic.com/"},{"site":"Digital Defynd","url":"https://digitaldefynd.com/"}],
30:[{"site":"The Micco","url":"https://www.themicco.com/"},{"site":"The Unsigned Guide","url":"https://www.theunsignedguide.com"},{"site":"Indie Bible","url":"https://indiebible.com/"},{"site":"Music Gateway","url":"https://www.musicgateway.com"},{"site":"Major Contacts","url":"https://majorcontacts.com/"}],
31:[{"site":"Canva","url":"https://www.canva.com/join/qwh-jbb-qxt"},{"site":"Stop Motion","url":"https://play.google.com/store/apps/details?id=com.cateater.stopmotionstudio&hl=en_CA&gl=US"},{"site":"Loopsie App","url":"https://play.google.com/store/apps/details?id=com.loopsie.android&hl=en_CA&gl=US"},{"site":"Video Leap App","url":"https://apps.apple.com/us/app/videoleap-editor-by-lightricks/id1255135442"},{"site":"Momento","url":"https://www.memento.com/"},{"site":"Instants App","url":"https://apps.apple.com/us/app/instants-photo-edition/id947399666"},{"site":"Mojo","url":"https://www.mojo-app.com/"}],
32:[{"site":"Authors AI","url":"https://authors.ai/"},{"site":"Books By","url":"https://booksby.ai/"},{"site":"Online-Convert","url":"https://www.online-convert.com/"},{"site":"Canva","url":"https://www.canva.com/join/qwh-jbb-qxt"},{"site":"Pro Writing Aid","url":"https://prowritingaid.com/"},{"site":"Deep Story AI","url":"https://deepstory.ai"},{"site":"My Identifiers","url":"https://www.myidentifiers.com/"},{"site":"Way With Words","url":"https://waywithwords.net/"}],
33:[{"site":"Skylum LuminarAI","url":"https://skylum.com/luminar-ai-b"},{"site":"Imagen-AI","url":"https://www.imagen-ai.com/"},{"site":"Pixelz","url":"https://www.pixelz.com/"}],
34:[{"site":"User Testing","url":"https://www.usertesting.com/"}],
35:[{"site":"Click Bank","url":"https://www.clickbank.com/"}],
36:[{"site":"Capterra","url":"https://www.capterra.com/"},{"site":"Google Ads","url":"https://ads.google.com/intl/en_ca/home/"}],
37:[{"site":"Skill Share","url":"https://www.skillshare.com/"}],
38:[{"site":"Name Lix","url":"https://namelix.com/"},{"site":"Copy.ai","url":"https://www.copy.ai/"},{"site":"Brand Mark","url":"https://brandmark.io/"},{"site":"Deep Image","url":"https://deep-image.ai/"},{"site":"Profile Pic Maker","url":"https://pfpmaker.com/"},{"site":"Deep Nostalgio","url":"https://www.myheritage.com/deep-nostalgia"},{"site":"Beautiful AI","url":"https://www.beautiful.ai/"},{"site":"Big JPG","url":"https://deep-image.ai/"},{"site":"Hotpot AI","url":"https://hotpot.ai/"}],
39:[{"site":"Jarvis AI","url":"https://www.jarvis.ai/"},{"site":"Builderall","url":"https://builderall.com/"},{"site":"Fan Fuel","url":"https://fanfuel.co/"},{"site":"Legendary Marketer","url":"https://legendarymarketer.com/"},{"site":"Amazing","url":"https://www.amazing.com/"}],
41:[{"site":"Click Bank","url":"https://www.clickbank.com/"},{"site":"AHREFS","url":"https://ahrefs.com/"}],
42:[{"site":"Amazon Seller App","url":"https://apps.apple.com/us/app/amazon-seller/id794141485"}],
43:[{"site":"Canva","url":"https://www.canva.com/join/qwh-jbb-qxt"},{"site":"TeeSpring","url":"https://teespring.com/"}],
44:[{"site":"Capterra","url":"https://www.capterra.com/"},{"site":"Google Ads","url":"https://ads.google.com/intl/en_ca/home/"}],
45:[{"site":"Click Bank","url":"https://www.clickbank.com/"}],
47:[{"site":"H-Supertools","url":"https://h-supertools.com/"},{"site":"Click Bank","url":"https://www.clickbank.com/"},{"site":"Pexels","url":"https://www.pexels.com/"},{"site":"Medium","url":"https://medium.com/"},{"site":"Duplichecker","url":"https://www.duplichecker.com/article-rewriter.php"},{"site":"Quara","url":"https://www.quora.com/"},{"site":"ChatGPT","url":"https://chat.openai.com/chat"},{"site":"Hugging Face - Output Detector","url":"https://huggingface.co/openai-detector/"}],
48:[{"site":"Bitly","url":"https://bitly.com/"},{"site":"Morning Fame","url":"https://morningfa.me/"},{"site":"ChatGPT","url":"https://chat.openai.com/chat"},{"site":"Social Blade","url":"https://socialblade.com/"},{"site":"Small SEO Tools","url":"https://smallseotools.com/"},{"site":"InVideo","url":"https://invideo.io/"},{"site":"Click Bank","url":"https://www.clickbank.com/"},{"site":"Canva","url":"https://www.canva.com/join/qwh-jbb-qxt"}],
}